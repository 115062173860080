@charset "UTF-8";
/* $footer-bg: linear-gradient(
  to bottom,
  rgba(var(--hn-body-bg-rgb), 1) 0%,
  rgba(var(--hn-body-bg-rgb), 1) 100%
);*/
/* DARK STYLES */
/* $footer-bg: linear-gradient(
  to bottom,
  rgba(var(--hn-body-bg-rgb), 1) 0%,
  rgba(var(--hn-body-bg-rgb), 1) 100%
);*/
@import url(https://fonts.bunny.net/css?family=ibm-plex-sans:400,600);
.searchdetail .objectcontact__realtor .objectcontact__mobilephone,
.searchdetail .objectcontact__realtor .objectcontact__phone {
  display: none;
}

:root,
[data-bs-theme=light] {
  --hn-blue: #0d6efd;
  --hn-indigo: #6610f2;
  --hn-purple: #6f42c1;
  --hn-pink: #d63384;
  --hn-red: #dc3545;
  --hn-orange: #fd7e14;
  --hn-yellow: #ffc107;
  --hn-green: #198754;
  --hn-teal: #20c997;
  --hn-cyan: #0dcaf0;
  --hn-black: #000;
  --hn-white: #fff;
  --hn-gray: #6c757d;
  --hn-gray-dark: #343a40;
  --hn-gray-100: #f8f9fa;
  --hn-gray-200: #e9ecef;
  --hn-gray-300: #dee2e6;
  --hn-gray-400: #ced4da;
  --hn-gray-500: #adb5bd;
  --hn-gray-600: #6c757d;
  --hn-gray-700: #495057;
  --hn-gray-800: #343a40;
  --hn-gray-900: #212529;
  --hn-primary: #f39205;
  --hn-secondary: #354047;
  --hn-success: #198754;
  --hn-info: #354047;
  --hn-warning: #ffc107;
  --hn-danger: #dc3545;
  --hn-light: #f8f9fa;
  --hn-dark: #212529;
  --hn-cta: white;
  --hn-default: #d6d6d6;
  --hn-primary-rgb: 243, 146, 5;
  --hn-secondary-rgb: 53, 64, 71;
  --hn-success-rgb: 25, 135, 84;
  --hn-info-rgb: 53, 64, 71;
  --hn-warning-rgb: 255, 193, 7;
  --hn-danger-rgb: 220, 53, 69;
  --hn-light-rgb: 248, 249, 250;
  --hn-dark-rgb: 33, 37, 41;
  --hn-cta-rgb: 255, 255, 255;
  --hn-default-rgb: 214, 214, 214;
  --hn-primary-text: rgb(5.2, 44, 101.2);
  --hn-secondary-text: rgb(43.2, 46.8, 50);
  --hn-success-text: rgb(10, 54, 33.6);
  --hn-info-text: rgb(5.2, 80.8, 96);
  --hn-warning-text: rgb(102, 77.2, 2.8);
  --hn-danger-text: rgb(88, 21.2, 27.6);
  --hn-light-text: #495057;
  --hn-dark-text: #495057;
  --hn-primary-bg-subtle: rgb(206.6, 226, 254.6);
  --hn-secondary-bg-subtle: rgb(225.6, 227.4, 229);
  --hn-success-bg-subtle: rgb(209, 231, 220.8);
  --hn-info-bg-subtle: rgb(206.6, 244.4, 252);
  --hn-warning-bg-subtle: rgb(255, 242.6, 205.4);
  --hn-danger-bg-subtle: rgb(248, 214.6, 217.8);
  --hn-light-bg-subtle: rgb(251.5, 252, 252.5);
  --hn-dark-bg-subtle: #ced4da;
  --hn-primary-border-subtle: rgb(158.2, 197, 254.2);
  --hn-secondary-border-subtle: rgb(196.2, 199.8, 203);
  --hn-success-border-subtle: rgb(163, 207, 186.6);
  --hn-info-border-subtle: rgb(158.2, 233.8, 249);
  --hn-warning-border-subtle: rgb(255, 230.2, 155.8);
  --hn-danger-border-subtle: rgb(241, 174.2, 180.6);
  --hn-light-border-subtle: #e9ecef;
  --hn-dark-border-subtle: #adb5bd;
  --hn-white-rgb: 255, 255, 255;
  --hn-black-rgb: 0, 0, 0;
  --hn-body-color-rgb: 53, 64, 71;
  --hn-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --hn-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --hn-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --hn-pageheader-bg: #f39205;
  --hn-pageheader-color: var(--hn-white);
  --hn-pageheader-title-color: var(--hn-white);
  --hn-pageheader-subtitle-color: var(--hn-white);
  --hn-placeholder-bg: #ffffff;
  --hn-boxshadow: 1px 22px 35px rgba(0, 0, 0, 0.1);
  --hn-headings-font-family: Inter, Inter-fallback, sans-serif;
  --hn-headings-color: #354047;
  --hn-headings-sub-color: #354047;
  --hn-headings-font-weight: 400;
  --hn-headings-sub-font-weight: 400;
  --hn-headings-text-transform: unset;
  --hn-headings-line-height: 1.182em;
  --hn-headings-letter-spacing: 0;
  --hn-header-logo-width: 200px;
  --hn-header-logo-width-sm: 200px;
  --hn-header-logo-width-md: 260px;
  --hn-header-logo-width-lg: 260px;
  --hn-header-logo-width-xl: 280px;
  --hn-swiper: var(--hn-primary);
  --hn-swiper-hover: rgb(193.0282258065, 115.9758064516, 3.9717741935);
  --hn-swiper-data-bg: rgb(255, 255, 255);
  --hn-swiper-data-color: var(--hn-primary);
  --hn-swiper-data-title-color: var(--hn-primary);
  --hn-swiper-data-subtitle-color: var(--hn-primary);
  --hn-swiper-scrollbar-bg: rgb(252.2883064516, 199.6814516129, 123.2116935484);
  --hn-swiper-scrollbar-drag-bg: var(--hn-primary);
  --hn-root-font-size: 16px;
  --hn-body-font-family: "Inter", "Inter-fallback", sans-serif;
  --hn-body-font-size: 1rem;
  --hn-body-font-weight: 300;
  --hn-body-line-height: 1.6;
  --hn-body-color: #354047;
  --hn-emphasis-color: #000;
  --hn-emphasis-color-rgb: 0, 0, 0;
  --hn-secondary-color: rgba(33, 37, 41, 0.75);
  --hn-secondary-color-rgb: 33, 37, 41;
  --hn-secondary-bg: #e9ecef;
  --hn-secondary-bg-rgb: 233, 236, 239;
  --hn-tertiary-color: rgba(33, 37, 41, 0.5);
  --hn-tertiary-color-rgb: 33, 37, 41;
  --hn-tertiary-bg: #f8f9fa;
  --hn-tertiary-bg-rgb: 248, 249, 250;
  --hn-body-bg: #ffffff;
  --hn-body-bg-rgb: 255, 255, 255;
  --hn-heading-color: #354047;
  --hn-link-color: #f39205;
  --hn-link-color-rgb: 243, 146, 5;
  --hn-link-decoration: underline;
  --hn-link-hover-color: rgb(193.0282258065, 115.9758064516, 3.9717741935);
  --hn-link-hover-color-rgb: 193, 116, 4;
  --hn-code-color: #d63384;
  --hn-highlight-bg: rgb(255, 242.6, 205.4);
  --hn-border-width: 1px;
  --hn-border-style: solid;
  --hn-border-color: #dee2e6;
  --hn-border-color-translucent: rgba(0, 0, 0, 0.175);
  --hn-border-radius: 12px;
  --hn-border-radius-sm: 0.25rem;
  --hn-border-radius-lg: 0.5rem;
  --hn-border-radius-xl: 1rem;
  --hn-border-radius-2xl: 2rem;
  --hn-border-radius-pill: 50rem;
  --hn-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --hn-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --hn-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --hn-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --hn-form-control-bg: var(--hn-tertiary-bg);
  --hn-form-control-disabled-bg: var(--hn-secondary-bg);
  --hn-breakpoint-xs: 0;
  --hn-breakpoint-sm: 576px;
  --hn-breakpoint-md: 768px;
  --hn-breakpoint-lg: 992px;
  --hn-breakpoint-xl: 1200px;
  --hn-breakpoint-xxl: 1400px;
  --hn-breakpoint-xxxl: 1920px;
}

.btn-primary {
  --hn-btn-color: #000;
  --hn-btn-bg: #f39205;
  --hn-btn-border-color: #f39205;
  --hn-btn-hover-color: #000;
  --hn-btn-hover-bg: rgb(244.8, 162.35, 42.5);
  --hn-btn-hover-border-color: rgb(244.2, 156.9, 30);
  --hn-btn-focus-shadow-rgb: 207, 124, 4;
  --hn-btn-active-color: #000;
  --hn-btn-active-bg: rgb(245.4, 167.8, 55);
  --hn-btn-active-border-color: rgb(244.2, 156.9, 30);
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #000;
  --hn-btn-disabled-bg: #f39205;
  --hn-btn-disabled-border-color: #f39205;
}

.btn-secondary {
  --hn-btn-color: #fff;
  --hn-btn-bg: #354047;
  --hn-btn-border-color: #354047;
  --hn-btn-hover-color: #fff;
  --hn-btn-hover-bg: rgb(45.05, 54.4, 60.35);
  --hn-btn-hover-border-color: rgb(42.4, 51.2, 56.8);
  --hn-btn-focus-shadow-rgb: 83, 93, 99;
  --hn-btn-active-color: #fff;
  --hn-btn-active-bg: rgb(42.4, 51.2, 56.8);
  --hn-btn-active-border-color: rgb(39.75, 48, 53.25);
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #fff;
  --hn-btn-disabled-bg: #354047;
  --hn-btn-disabled-border-color: #354047;
}

.btn-success {
  --hn-btn-color: #fff;
  --hn-btn-bg: #198754;
  --hn-btn-border-color: #198754;
  --hn-btn-hover-color: #fff;
  --hn-btn-hover-bg: rgb(21.25, 114.75, 71.4);
  --hn-btn-hover-border-color: rgb(20, 108, 67.2);
  --hn-btn-focus-shadow-rgb: 60, 153, 110;
  --hn-btn-active-color: #fff;
  --hn-btn-active-bg: rgb(20, 108, 67.2);
  --hn-btn-active-border-color: rgb(18.75, 101.25, 63);
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #fff;
  --hn-btn-disabled-bg: #198754;
  --hn-btn-disabled-border-color: #198754;
}

.btn-info {
  --hn-btn-color: #fff;
  --hn-btn-bg: #354047;
  --hn-btn-border-color: #354047;
  --hn-btn-hover-color: #fff;
  --hn-btn-hover-bg: rgb(45.05, 54.4, 60.35);
  --hn-btn-hover-border-color: rgb(42.4, 51.2, 56.8);
  --hn-btn-focus-shadow-rgb: 83, 93, 99;
  --hn-btn-active-color: #fff;
  --hn-btn-active-bg: rgb(42.4, 51.2, 56.8);
  --hn-btn-active-border-color: rgb(39.75, 48, 53.25);
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #fff;
  --hn-btn-disabled-bg: #354047;
  --hn-btn-disabled-border-color: #354047;
}

.btn-warning {
  --hn-btn-color: #000;
  --hn-btn-bg: #ffc107;
  --hn-btn-border-color: #ffc107;
  --hn-btn-hover-color: #000;
  --hn-btn-hover-bg: rgb(255, 202.3, 44.2);
  --hn-btn-hover-border-color: rgb(255, 199.2, 31.8);
  --hn-btn-focus-shadow-rgb: 217, 164, 6;
  --hn-btn-active-color: #000;
  --hn-btn-active-bg: rgb(255, 205.4, 56.6);
  --hn-btn-active-border-color: rgb(255, 199.2, 31.8);
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #000;
  --hn-btn-disabled-bg: #ffc107;
  --hn-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --hn-btn-color: #fff;
  --hn-btn-bg: #dc3545;
  --hn-btn-border-color: #dc3545;
  --hn-btn-hover-color: #fff;
  --hn-btn-hover-bg: rgb(187, 45.05, 58.65);
  --hn-btn-hover-border-color: rgb(176, 42.4, 55.2);
  --hn-btn-focus-shadow-rgb: 225, 83, 97;
  --hn-btn-active-color: #fff;
  --hn-btn-active-bg: rgb(176, 42.4, 55.2);
  --hn-btn-active-border-color: rgb(165, 39.75, 51.75);
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #fff;
  --hn-btn-disabled-bg: #dc3545;
  --hn-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --hn-btn-color: #000;
  --hn-btn-bg: #f8f9fa;
  --hn-btn-border-color: #f8f9fa;
  --hn-btn-hover-color: #000;
  --hn-btn-hover-bg: rgb(210.8, 211.65, 212.5);
  --hn-btn-hover-border-color: rgb(198.4, 199.2, 200);
  --hn-btn-focus-shadow-rgb: 211, 212, 213;
  --hn-btn-active-color: #000;
  --hn-btn-active-bg: rgb(198.4, 199.2, 200);
  --hn-btn-active-border-color: rgb(186, 186.75, 187.5);
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #000;
  --hn-btn-disabled-bg: #f8f9fa;
  --hn-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --hn-btn-color: #fff;
  --hn-btn-bg: #212529;
  --hn-btn-border-color: #212529;
  --hn-btn-hover-color: #fff;
  --hn-btn-hover-bg: rgb(66.3, 69.7, 73.1);
  --hn-btn-hover-border-color: rgb(55.2, 58.8, 62.4);
  --hn-btn-focus-shadow-rgb: 66, 70, 73;
  --hn-btn-active-color: #fff;
  --hn-btn-active-bg: rgb(77.4, 80.6, 83.8);
  --hn-btn-active-border-color: rgb(55.2, 58.8, 62.4);
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #fff;
  --hn-btn-disabled-bg: #212529;
  --hn-btn-disabled-border-color: #212529;
}

.btn-cta {
  --hn-btn-color: #000;
  --hn-btn-bg: white;
  --hn-btn-border-color: white;
  --hn-btn-hover-color: #000;
  --hn-btn-hover-bg: white;
  --hn-btn-hover-border-color: white;
  --hn-btn-focus-shadow-rgb: 217, 217, 217;
  --hn-btn-active-color: #000;
  --hn-btn-active-bg: white;
  --hn-btn-active-border-color: white;
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #000;
  --hn-btn-disabled-bg: white;
  --hn-btn-disabled-border-color: white;
}

.btn-default {
  --hn-btn-color: #000;
  --hn-btn-bg: #d6d6d6;
  --hn-btn-border-color: #d6d6d6;
  --hn-btn-hover-color: #000;
  --hn-btn-hover-bg: rgb(220.15, 220.15, 220.15);
  --hn-btn-hover-border-color: rgb(218.1, 218.1, 218.1);
  --hn-btn-focus-shadow-rgb: 182, 182, 182;
  --hn-btn-active-color: #000;
  --hn-btn-active-bg: rgb(222.2, 222.2, 222.2);
  --hn-btn-active-border-color: rgb(218.1, 218.1, 218.1);
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #000;
  --hn-btn-disabled-bg: #d6d6d6;
  --hn-btn-disabled-border-color: #d6d6d6;
}

.btn-outline-primary {
  --hn-btn-color: #f39205;
  --hn-btn-border-color: #f39205;
  --hn-btn-hover-color: #000;
  --hn-btn-hover-bg: #f39205;
  --hn-btn-hover-border-color: #f39205;
  --hn-btn-focus-shadow-rgb: 243, 146, 5;
  --hn-btn-active-color: #000;
  --hn-btn-active-bg: #f39205;
  --hn-btn-active-border-color: #f39205;
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #f39205;
  --hn-btn-disabled-bg: transparent;
  --hn-btn-disabled-border-color: #f39205;
  --hn-gradient: none;
}

.btn-outline-secondary {
  --hn-btn-color: #354047;
  --hn-btn-border-color: #354047;
  --hn-btn-hover-color: #fff;
  --hn-btn-hover-bg: #354047;
  --hn-btn-hover-border-color: #354047;
  --hn-btn-focus-shadow-rgb: 53, 64, 71;
  --hn-btn-active-color: #fff;
  --hn-btn-active-bg: #354047;
  --hn-btn-active-border-color: #354047;
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #354047;
  --hn-btn-disabled-bg: transparent;
  --hn-btn-disabled-border-color: #354047;
  --hn-gradient: none;
}

.btn-outline-success {
  --hn-btn-color: #198754;
  --hn-btn-border-color: #198754;
  --hn-btn-hover-color: #fff;
  --hn-btn-hover-bg: #198754;
  --hn-btn-hover-border-color: #198754;
  --hn-btn-focus-shadow-rgb: 25, 135, 84;
  --hn-btn-active-color: #fff;
  --hn-btn-active-bg: #198754;
  --hn-btn-active-border-color: #198754;
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #198754;
  --hn-btn-disabled-bg: transparent;
  --hn-btn-disabled-border-color: #198754;
  --hn-gradient: none;
}

.btn-outline-info {
  --hn-btn-color: #354047;
  --hn-btn-border-color: #354047;
  --hn-btn-hover-color: #fff;
  --hn-btn-hover-bg: #354047;
  --hn-btn-hover-border-color: #354047;
  --hn-btn-focus-shadow-rgb: 53, 64, 71;
  --hn-btn-active-color: #fff;
  --hn-btn-active-bg: #354047;
  --hn-btn-active-border-color: #354047;
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #354047;
  --hn-btn-disabled-bg: transparent;
  --hn-btn-disabled-border-color: #354047;
  --hn-gradient: none;
}

.btn-outline-warning {
  --hn-btn-color: #ffc107;
  --hn-btn-border-color: #ffc107;
  --hn-btn-hover-color: #000;
  --hn-btn-hover-bg: #ffc107;
  --hn-btn-hover-border-color: #ffc107;
  --hn-btn-focus-shadow-rgb: 255, 193, 7;
  --hn-btn-active-color: #000;
  --hn-btn-active-bg: #ffc107;
  --hn-btn-active-border-color: #ffc107;
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #ffc107;
  --hn-btn-disabled-bg: transparent;
  --hn-btn-disabled-border-color: #ffc107;
  --hn-gradient: none;
}

.btn-outline-danger {
  --hn-btn-color: #dc3545;
  --hn-btn-border-color: #dc3545;
  --hn-btn-hover-color: #fff;
  --hn-btn-hover-bg: #dc3545;
  --hn-btn-hover-border-color: #dc3545;
  --hn-btn-focus-shadow-rgb: 220, 53, 69;
  --hn-btn-active-color: #fff;
  --hn-btn-active-bg: #dc3545;
  --hn-btn-active-border-color: #dc3545;
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #dc3545;
  --hn-btn-disabled-bg: transparent;
  --hn-btn-disabled-border-color: #dc3545;
  --hn-gradient: none;
}

.btn-outline-light {
  --hn-btn-color: #f8f9fa;
  --hn-btn-border-color: #f8f9fa;
  --hn-btn-hover-color: #000;
  --hn-btn-hover-bg: #f8f9fa;
  --hn-btn-hover-border-color: #f8f9fa;
  --hn-btn-focus-shadow-rgb: 248, 249, 250;
  --hn-btn-active-color: #000;
  --hn-btn-active-bg: #f8f9fa;
  --hn-btn-active-border-color: #f8f9fa;
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #f8f9fa;
  --hn-btn-disabled-bg: transparent;
  --hn-btn-disabled-border-color: #f8f9fa;
  --hn-gradient: none;
}

.btn-outline-dark {
  --hn-btn-color: #212529;
  --hn-btn-border-color: #212529;
  --hn-btn-hover-color: #fff;
  --hn-btn-hover-bg: #212529;
  --hn-btn-hover-border-color: #212529;
  --hn-btn-focus-shadow-rgb: 33, 37, 41;
  --hn-btn-active-color: #fff;
  --hn-btn-active-bg: #212529;
  --hn-btn-active-border-color: #212529;
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #212529;
  --hn-btn-disabled-bg: transparent;
  --hn-btn-disabled-border-color: #212529;
  --hn-gradient: none;
}

.btn-outline-cta {
  --hn-btn-color: white;
  --hn-btn-border-color: white;
  --hn-btn-hover-color: #000;
  --hn-btn-hover-bg: white;
  --hn-btn-hover-border-color: white;
  --hn-btn-focus-shadow-rgb: 255, 255, 255;
  --hn-btn-active-color: #000;
  --hn-btn-active-bg: white;
  --hn-btn-active-border-color: white;
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: white;
  --hn-btn-disabled-bg: transparent;
  --hn-btn-disabled-border-color: white;
  --hn-gradient: none;
}

.btn-outline-default {
  --hn-btn-color: #d6d6d6;
  --hn-btn-border-color: #d6d6d6;
  --hn-btn-hover-color: #000;
  --hn-btn-hover-bg: #d6d6d6;
  --hn-btn-hover-border-color: #d6d6d6;
  --hn-btn-focus-shadow-rgb: 214, 214, 214;
  --hn-btn-active-color: #000;
  --hn-btn-active-bg: #d6d6d6;
  --hn-btn-active-border-color: #d6d6d6;
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #d6d6d6;
  --hn-btn-disabled-bg: transparent;
  --hn-btn-disabled-border-color: #d6d6d6;
  --hn-gradient: none;
}

.btn-link {
  --hn-btn-font-weight: 400;
  --hn-btn-color: var(--hn-link-color);
  --hn-btn-bg: transparent;
  --hn-btn-border-color: transparent;
  --hn-btn-hover-color: var(--hn-link-hover-color);
  --hn-btn-hover-border-color: transparent;
  --hn-btn-active-color: var(--hn-link-hover-color);
  --hn-btn-active-border-color: transparent;
  --hn-btn-disabled-color: #6c757d;
  --hn-btn-disabled-border-color: transparent;
  --hn-btn-box-shadow: none;
  --hn-btn-focus-shadow-rgb: 207, 124, 4;
  text-decoration: underline;
}
.btn-link:focus {
  color: var(--hn-btn-color);
}
.btn-link:hover {
  color: var(--hn-btn-hover-color);
}

.btn {
  --hn-btn-font-weight: 500;
  --hn-btn-padding-y: 0.5rem;
  --hn-btn-padding-x: 1rem;
  --hn-btn-font-size: 1rem;
  --hn-btn-border-radius: 1.5rem;
}

.btn-lg {
  --hn-btn-padding-y: 0.5rem;
  --hn-btn-padding-x: 1rem;
  --hn-btn-font-size: 1.25rem;
  --hn-btn-border-radius: 1.5rem;
}

.btn-sm {
  --hn-btn-padding-y: 0.25rem;
  --hn-btn-padding-x: 0.5rem;
  --hn-btn-font-size: 0.875rem;
  --hn-btn-border-radius: 1.5rem;
}

.title {
  --hn-title-font-family: var(--hn-headings-font-family);
  --hn-title-line-height: var(--hn-headings-line-height);
  --hn-title-letter-spacing: var(--hn-headings-letter-spacing);
  --hn-title-text-transform: var(--hn-headings-text-transform);
  --hn-title-font-weight: var(--hn-headings-font-weight);
  --hn-title-color: var(--hn-headings-color);
}

.mainSlider {
  --hn-main-slider-bg: transparent;
  --hn-main-slider-color: #354047;
  --hn-main-slider-title-color: #354047;
  --hn-main-slider-title-weight: 700;
  --hn-main-slider-subtitle-color: var(--hn-headings-sub-color);
  --hn-main-slider-subtitle-weight: 300;
  --hn-main-slider-before-gradient: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
  --hn-main-slider-after-gradient: linear-gradient(to top, transparent 0%, rgba(255, 255, 255, 0) 100%);
  --hn-swiper: #354047;
  --hn-swiper-scrollbar-drag-bg: #fff;
  --hn-swiper-scrollbar-bg: rgba(255, 255, 255, 0.5);
}

.header {
  --hn-header-bg: #fff;
  --hn-header-color: #000;
  --hn-header-navbar-toggle-color: rgb(255, 255, 255);
  --hn-header-navbar-toggle-bg: rgb(0, 0, 0);
  --hn-header-navbar-toggle-border-color: rgb(0, 0, 0);
}

.header__mainmenu {
  --hn-offcanvas-width: 400px;
  --hn-offcanvas-height: 30vh;
  --hn-offcanvas-padding-x: 1rem;
  --hn-offcanvas-padding-y: 1rem;
  --hn-offcanvas-color: var(--hn-white);
  --hn-offcanvas-bg: var(--hn-primary-light);
  --hn-offcanvas-border-width: var(--hn-border-width);
  --hn-offcanvas-border-color: transparent;
  --hn-offcanvas-box-shadow: var(--hn-box-shadow-sm);
}
.header__mainmenu .nav-link {
  --hn-nav-link-font-size: 1rem;
  --hn-nav-link-font-weight: 300;
  --hn-nav-link-color: white;
  --hn-nav-link-hover-color: dark(white, 10%);
  --hn-nav-link-disabled-color: #d6d6d6;
}

.header__topmenu .nav > .nav-item > .nav-link {
  --hn-nav-link-color: #fff;
  --hn-nav-link-font-size: 1.125rem;
  --hn-nav-link-font-weight: 500;
  --hn-nav-link-hover-color: #f39205;
  --hn-nav-link-active-color: #f39205;
  --hn-nav-link-disabled-color: #d6d6d6;
}
.header__topmenu .nav > .nav-item .dropdown-menu .nav-item .nav-link {
  --hn-nav-link-color: var(--hn-body-color);
  --hn-nav-link-hover-color: #f39205;
  --hn-nav-link-active-color: #f39205;
  --hn-nav-link-disabled-color: #d6d6d6;
}

.header__toggle {
  --hn-header-navbar-toggle-color: rgb(0, 0, 0);
  --hn-header-navbar-toggle-bg: rgb(255, 255, 255);
  --hn-header-navbar-toggle-border-color: rgb(0, 0, 0);
}
.header__toggle:hover {
  --hn-header-navbar-toggle-color: #f39205;
  --hn-header-navbar-toggle-bg: rgb(255, 255, 255);
  --hn-header-navbar-toggle-border-color: rgb(0, 0, 0);
}

.home .header--top .header__topmenu .nav > .nav-item > .nav-link,
.company .header--top .header__topmenu .nav > .nav-item > .nav-link,
.textheader .header--top .header__topmenu .nav > .nav-item > .nav-link {
  --hn-nav-link-color: var(--hn-headings-color);
  --hn-nav-link-hover-color: #f39205;
  --hn-nav-link-disabled-color: #d6d6d6;
  --hn-nav-link-active-color: #f39205;
}
.home .header--top .header__toggle,
.company .header--top .header__toggle,
.textheader .header--top .header__toggle {
  --hn-header-navbar-toggle-color: rgb(0, 0, 0);
  --hn-header-navbar-toggle-bg: rgb(255, 255, 255);
  --hn-header-navbar-toggle-border-color: rgb(0, 0, 0);
}
.home .header--top .header__toggle:hover,
.company .header--top .header__toggle:hover,
.textheader .header--top .header__toggle:hover {
  --hn-header-navbar-toggle-color: #f39205;
  --hn-header-navbar-toggle-bg: rgb(255, 255, 255);
  --hn-header-navbar-toggle-border-color: rgb(0, 0, 0);
}

.services {
  --hn-services-bg: transparent;
  --hn-services-gradient: linear-gradient(to bottom, transparent 0%, rgba(255, 255, 255, 0) 100%);
  --hn-services-color: var(--hn-body-color);
  --hn-services-title: #354047;
  --hn-services-subtitle: var(--hn-headings-sub-color);
  --hn-services-item-bg: #444;
  --hn-services-item-color: #444;
  --hn-services-item-border-radius: var(--hn-border-radius);
  --hn-services-item-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  --hn-services-item-text-bg: rgb(16, 57, 94, 0.3);
  --hn-services-item-text-gradient: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  --hn-services-item-text-color: #fff;
}

.process__step {
  --hn-process-step-bg: #fff;
  --hn-process-step-color: $body-color;
  --hn-process-step-box-shadow: 1px 22px 35px rgba(0, 0, 0, 0.1);
  --hn-process-step-border-radius: var(--hn-border-radius);
  --hn-process-step-img-bg: var(--hn-gray-300);
  --hn-process-step-link-color: var(--hn-link-color);
  --hn-process-step-link-hover-color: var(--hn-link-hover-color);
  --hn-process-step-link-gradient: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 63%
  );
}

.company__form {
  --hn-company-form-bg: var(--hn-secondary-bg);
}

.section.pageheader {
  --hn-pageheader-image-bg: #354047;
  --hn-pageheader-image-color: var(--hn-pageheader-color);
  --hn-pageheader-image-title-color: var(--hn-pageheader-title-color);
  --hn-pageheader-image-title-weight: 700;
  --hn-pageheader-image-subtitle-color: var(--hn-pageheader-subtitle-color);
  --hn-pageheader-image-subtitle-weight: 700;
  --hn-pageheader-image-gradient: none;
  --hn-pageheader-image-before-gradient: none;
  --hn-pageheader-image-after-gradient: none;
}

.textheader {
  --hn-first-section-after-pageheader-bg: rgb(16, 57, 94);
  --hn-first-section-after-pageheader-gradient: linear-gradient(to bottom, transparent 0%, rgba(255, 255, 255, 0) 100%);
  --hn-first-section-after-pageheader-color: var(--hn-body-color);
  --hn-first-section-after-pageheader-title: var(--hn-headings-color);
  --hn-first-section-after-pageheader-subtitle: var(--hn-headings-sub-color);
}

.footer {
  --hn-footer-bg: var(--hn-primary-light);
  --hn-footer-color: var(--hn-body-color);
  --hn-footer-header-color: var(--hn-headings-color);
  --hn-footer-icon-border: 1px solid var(--hn-body-color);
  --hn-footer-social-color: var(--hn-body-color);
  --hn-footer-social-border: 1px solid var(--hn-body-color);
  --hn-sub-footer-bg: var(--hn-primary-light);
  --hn-sub-footer-color: var(--hn-body-color);
}

.card {
  --hn-card-spacer-y: 1rem;
  --hn-card-spacer-x: 1rem;
  --hn-card-title-spacer-y: 0.5rem;
  --hn-card-border-width: var(--hn-border-width);
  --hn-card-border-color: var(--hn-border-color-translucent);
  --hn-card-border-radius: var(--hn-border-radius);
  --hn-card-box-shadow: ;
  --hn-card-inner-border-radius: calc(var(--hn-border-radius) - (var(--hn-border-width)));
  --hn-card-cap-padding-y: 0.5rem;
  --hn-card-cap-padding-x: 1rem;
  --hn-card-cap-bg: rgba(255, 255, 255, 0.05);
  --hn-card-cap-color: ;
  --hn-card-height: ;
  --hn-card-color: ;
  --hn-card-bg: var(--hn-swiper-data-bg);
  --hn-card-img-overlay-padding: 1rem;
  --hn-card-group-margin: 0.75rem;
}

.nav-tabs {
  --hn-nav-tabs-border-width: 1px;
  --hn-nav-tabs-border-color: #dee2e6;
  --hn-nav-tabs-border-radius: 12px;
  --hn-nav-tabs-link-hover-border-color: #dee2e6;
  --hn-nav-tabs-link-active-color: #fff;
  --hn-nav-tabs-link-active-bg: #f39205;
  --hn-nav-tabs-link-active-border-color: #dee2e6;
}

.object.project,
.listings .object,
.object_list .object {
  --hn-object-bg: var(--hn-swiper-data-bg);
  --hn-object-color: var(--hn-swiper-data-color);
  --hn-object-link-color: var(--hn-link-color);
  --hn-object-link-hover-color: var(--hn-link-hover-color);
  --hn-object-title-color: var(--hn-headings-color);
  --hn-object-salestitle-color: var(--hn-object-color);
  --hn-object-feautre-color: var(--hn-object-link-color);
  --hn-object-stats-color: var(--hn-object-link-color);
  --hn-object-border-color: var(--hn-border-color);
  --hn-object-border-radius: var(--hn-border-radius);
  --hn-object-top-bg: #fbf2e1;
  --hn-object-top-color: var(--hn-swiper-data-color);
  --hn-object-special-bg: #e6f2f7;
  --hn-object-special-color: var(--hn-swiper-data-color);
}

.object.project,
.listings .object {
  --hn-object-bg: var(--hn-swiper-data-bg);
  --hn-object-color: var(--hn-swiper-data-color);
  --hn-object-border-radius: var(--hn-border-radius);
  --hn-object-boxshadow: var(--hn-boxshadow);
  --hn-object-title-color: var(--hn-swiper-data-title-color);
  --hn-object-subtitle-color: var(
    --hn-swiper-data-subtitle-color
  );
  --hn-object-price-color: white;
  --hn-object-sub-price-color: var(--hn-body-color);
  --hn-object-icon-color: var(--hn-primary);
}

.usps__cards-item {
  --hn-usp-card-item-title-color: #f39205;
  --hn-usp-card-item-color: var(--hn-swiper-data-color);
  --hn-usp-card-item-bg: var(--hn-swiper-data-bg);
  --hn-usp-card-item-border-radius: var(hn-border-radius);
}

.pagination {
  --hn-pagination-padding-x: 0.75rem;
  --hn-pagination-padding-y: 0.375rem;
  --hn-pagination-font-size: 1rem;
  --hn-pagination-color: #354047;
  --hn-pagination-bg: #f39205;
  --hn-pagination-border-width: 1px;
  --hn-pagination-border-color: #dee2e6;
  --hn-pagination-border-radius: 0.375rem;
  --hn-pagination-hover-color: #f39205;
  --hn-pagination-hover-bg: #f39205;
  --hn-pagination-hover-border-color: #dee2e6;
  --hn-pagination-focus-color: #f39205;
  --hn-pagination-focus-bg: #e9ecef;
  --hn-pagination-focus-box-shadow: 0 0 0 0.25rem, rgba(13, 110, 253, 0.25);
  --hn-pagination-active-color: #fff;
  --hn-pagination-active-bg: #f39205;
  --hn-pagination-active-border-color: #f39205;
  --hn-pagination-disabled-color: #6c757d;
  --hn-pagination-disabled-bg: #fff;
  --hn-pagination-disabled-border-color: #dee2e6;
}

.modal {
  --hn-modal-color: ;
  --hn-modal-bg: var(--hn-body-bg);
  --hn-modal-border-color: var(--hn-border-color-translucent);
  --hn-modal-header-border-color: var(--hn-border-color);
  --hn-modal-footer-bg: ;
  --hn-modal-footer-border-color: var(--hn-border-color);
}

.accordion {
  --hn-accordion-color: var(--hn-swiper-data-color);
  --hn-accordion-bg: var(--hn-swiper-data-bg);
  --hn-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --hn-accordion-border-color: var(--hn-border-color);
  --hn-accordion-border-width: var(--hn-border-width);
  --hn-accordion-border-radius: var(--hn-border-radius);
  --hn-accordion-inner-border-radius: calc(var(--hn-border-radius) - (var(--hn-border-width)));
  --hn-accordion-btn-padding-x: 1.25rem;
  --hn-accordion-btn-padding-y: 1rem;
  --hn-accordion-btn-color: var(--hn-body-color);
  --hn-accordion-btn-bg: var(--hn-accordion-bg);
  --hn-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23354047'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --hn-accordion-btn-icon-width: 1.25rem;
  --hn-accordion-btn-icon-transform: rotate(-180deg);
  --hn-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --hn-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23324b4f'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --hn-accordion-btn-focus-border-color: rgb(134, 182.5, 254);
  --hn-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --hn-accordion-body-padding-x: 1.25rem;
  --hn-accordion-body-padding-y: 1rem;
  --hn-accordion-active-color: var(--hn-body-color);
  --hn-accordion-active-bg: var(--hn-primary-bg-subtle);
}

.nav-pills {
  --hn-nav-pills-link-active-color: #324b4f;
  --hn-nav-pills-link-active-bg: #f39205;
}

[data-bs-theme=dark] {
  /* redefine theme color variables */
  --hn-cta: white;
  --hn-primary: #f39205;
  --hn-secondary: black;
  --hn-default: #d6d6d6;
  --hn-success: #198754;
  --hn-info: #354047;
  --hn-warning: #ffc107;
  --hn-danger: #dc3545;
  --hn-dark: #212529;
  --hn-light: #f8f9fa;
  --hn-cta-rgb: 255, 255, 255;
  --hn-primary-rgb: 243, 146, 5;
  --hn-secondary-rgb: 0, 0, 0;
  --hn-default-rgb: 214, 214, 214;
  --hn-success-rgb: 25, 135, 84;
  --hn-info-rgb: 53, 64, 71;
  --hn-warning-rgb: 255, 193, 7;
  --hn-danger-rgb: 220, 53, 69;
  --hn-dark-rgb: 33, 37, 41;
  --hn-light-rgb: 248, 249, 250;
  --hn-body-color: #d5cccc;
  --hn-body-color-rgb: 213, 204, 204;
  --hn-body-bg: #180000;
  --hn-body-bg-rgb: 24, 0, 0;
  --hn-emphasis-color: #f8f9fa;
  --hn-emphasis-color-rgb: 248, 249, 250;
  --hn-secondary-color: rgba(213, 204, 204, 0.75);
  --hn-secondary-color-rgb: 213, 204, 204;
  --hn-secondary-bg: #343a40;
  --hn-secondary-bg-rgb: 52, 58, 64;
  --hn-tertiary-color: rgba(213, 204, 204, 0.5);
  --hn-tertiary-color-rgb: 213, 204, 204;
  --hn-tertiary-bg: rgb(42.5, 47.5, 52.5);
  --hn-tertiary-bg-rgb: 43, 48, 53;
  --hn-primary-text: #000;
  --hn-secondary-text: #dee2e6;
  --hn-success-text: rgb(117, 183, 152.4);
  --hn-info-text: rgb(109.8, 223.2, 246);
  --hn-warning-text: rgb(255, 217.8, 106.2);
  --hn-danger-text: rgb(234, 133.8, 143.4);
  --hn-light-text: #f8f9fa;
  --hn-dark-text: #dee2e6;
  --hn-primary-bg-subtle: rgb(2.6, 22, 50.6);
  --hn-secondary-bg-subtle: rgb(21.6, 23.4, 25);
  --hn-success-bg-subtle: rgb(5, 27, 16.8);
  --hn-info-bg-subtle: rgb(2.6, 40.4, 48);
  --hn-warning-bg-subtle: rgb(51, 38.6, 1.4);
  --hn-danger-bg-subtle: rgb(44, 10.6, 13.8);
  --hn-light-bg-subtle: #343a40;
  --hn-dark-bg-subtle: #1a1d20;
  --hn-primary-border-subtle: rgb(7.8, 66, 151.8);
  --hn-secondary-border-subtle: rgb(64.8, 70.2, 75);
  --hn-success-border-subtle: rgb(15, 81, 50.4);
  --hn-info-border-subtle: rgb(7.8, 121.2, 144);
  --hn-warning-border-subtle: rgb(153, 115.8, 4.2);
  --hn-danger-border-subtle: rgb(132, 31.8, 41.4);
  --hn-light-border-subtle: #495057;
  --hn-dark-border-subtle: #343a40;
  --hn-heading-color: #fff;
  --hn-link-color: #f39205;
  --hn-link-hover-color: #f39205;
  --hn-link-color-rgb: 243, 146, 5;
  --hn-link-hover-color-rgb: 243, 146, 5;
  --hn-code-color: rgb(230.4, 132.6, 181.2);
  --hn-border-color: #495057;
  --hn-border-color-translucent: rgba(255, 255, 255, 0.15);
  --hn-pageheader-bg: #222222;
  --hn-placeholder-bg: #222222;
  --hn-headings-color: #fff;
  --hn-headings-sub-color: #f39205;
  --hn-swiper: #d5cccc;
  --hn-swiper-hover: #f39205;
  --hn-swiper-data-bg: var(--hn-pageheader-bg);
  --hn-swiper-data-color: var(--hn-swiper);
  --hn-swiper-data-title-color: var(--hn-headings-color);
  --hn-swiper-data-subtitle-color: var(--hn-swiper);
  --hn-swiper-scrollbar-bg: rgba(255, 255, 255, 0.1);
  --hn-swiper-scrollbar-drag-bg: var(--hn-swiper);
}
[data-bs-theme=dark] .btn-cta {
  --hn-btn-color: #000;
  --hn-btn-bg: white;
  --hn-btn-border-color: white;
  --hn-btn-hover-color: #000;
  --hn-btn-hover-bg: white;
  --hn-btn-hover-border-color: white;
  --hn-btn-focus-shadow-rgb: 217, 217, 217;
  --hn-btn-active-color: #000;
  --hn-btn-active-bg: white;
  --hn-btn-active-border-color: white;
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #000;
  --hn-btn-disabled-bg: white;
  --hn-btn-disabled-border-color: white;
}
[data-bs-theme=dark] .btn-primary {
  --hn-btn-color: #000;
  --hn-btn-bg: #f39205;
  --hn-btn-border-color: #f39205;
  --hn-btn-hover-color: #000;
  --hn-btn-hover-bg: rgb(244.8, 162.35, 42.5);
  --hn-btn-hover-border-color: rgb(244.2, 156.9, 30);
  --hn-btn-focus-shadow-rgb: 207, 124, 4;
  --hn-btn-active-color: #000;
  --hn-btn-active-bg: rgb(245.4, 167.8, 55);
  --hn-btn-active-border-color: rgb(244.2, 156.9, 30);
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #000;
  --hn-btn-disabled-bg: #f39205;
  --hn-btn-disabled-border-color: #f39205;
}
[data-bs-theme=dark] .btn-secondary {
  --hn-btn-color: #fff;
  --hn-btn-bg: black;
  --hn-btn-border-color: black;
  --hn-btn-hover-color: #fff;
  --hn-btn-hover-bg: black;
  --hn-btn-hover-border-color: black;
  --hn-btn-focus-shadow-rgb: 38, 38, 38;
  --hn-btn-active-color: #fff;
  --hn-btn-active-bg: black;
  --hn-btn-active-border-color: black;
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #fff;
  --hn-btn-disabled-bg: black;
  --hn-btn-disabled-border-color: black;
}
[data-bs-theme=dark] .btn-default {
  --hn-btn-color: #000;
  --hn-btn-bg: #d6d6d6;
  --hn-btn-border-color: #d6d6d6;
  --hn-btn-hover-color: #000;
  --hn-btn-hover-bg: rgb(220.15, 220.15, 220.15);
  --hn-btn-hover-border-color: rgb(218.1, 218.1, 218.1);
  --hn-btn-focus-shadow-rgb: 182, 182, 182;
  --hn-btn-active-color: #000;
  --hn-btn-active-bg: rgb(222.2, 222.2, 222.2);
  --hn-btn-active-border-color: rgb(218.1, 218.1, 218.1);
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #000;
  --hn-btn-disabled-bg: #d6d6d6;
  --hn-btn-disabled-border-color: #d6d6d6;
}
[data-bs-theme=dark] .btn-success {
  --hn-btn-color: #fff;
  --hn-btn-bg: #198754;
  --hn-btn-border-color: #198754;
  --hn-btn-hover-color: #fff;
  --hn-btn-hover-bg: rgb(21.25, 114.75, 71.4);
  --hn-btn-hover-border-color: rgb(20, 108, 67.2);
  --hn-btn-focus-shadow-rgb: 60, 153, 110;
  --hn-btn-active-color: #fff;
  --hn-btn-active-bg: rgb(20, 108, 67.2);
  --hn-btn-active-border-color: rgb(18.75, 101.25, 63);
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #fff;
  --hn-btn-disabled-bg: #198754;
  --hn-btn-disabled-border-color: #198754;
}
[data-bs-theme=dark] .btn-info {
  --hn-btn-color: #fff;
  --hn-btn-bg: #354047;
  --hn-btn-border-color: #354047;
  --hn-btn-hover-color: #fff;
  --hn-btn-hover-bg: rgb(45.05, 54.4, 60.35);
  --hn-btn-hover-border-color: rgb(42.4, 51.2, 56.8);
  --hn-btn-focus-shadow-rgb: 83, 93, 99;
  --hn-btn-active-color: #fff;
  --hn-btn-active-bg: rgb(42.4, 51.2, 56.8);
  --hn-btn-active-border-color: rgb(39.75, 48, 53.25);
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #fff;
  --hn-btn-disabled-bg: #354047;
  --hn-btn-disabled-border-color: #354047;
}
[data-bs-theme=dark] .btn-warning {
  --hn-btn-color: #000;
  --hn-btn-bg: #ffc107;
  --hn-btn-border-color: #ffc107;
  --hn-btn-hover-color: #000;
  --hn-btn-hover-bg: rgb(255, 202.3, 44.2);
  --hn-btn-hover-border-color: rgb(255, 199.2, 31.8);
  --hn-btn-focus-shadow-rgb: 217, 164, 6;
  --hn-btn-active-color: #000;
  --hn-btn-active-bg: rgb(255, 205.4, 56.6);
  --hn-btn-active-border-color: rgb(255, 199.2, 31.8);
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #000;
  --hn-btn-disabled-bg: #ffc107;
  --hn-btn-disabled-border-color: #ffc107;
}
[data-bs-theme=dark] .btn-danger {
  --hn-btn-color: #fff;
  --hn-btn-bg: #dc3545;
  --hn-btn-border-color: #dc3545;
  --hn-btn-hover-color: #fff;
  --hn-btn-hover-bg: rgb(187, 45.05, 58.65);
  --hn-btn-hover-border-color: rgb(176, 42.4, 55.2);
  --hn-btn-focus-shadow-rgb: 225, 83, 97;
  --hn-btn-active-color: #fff;
  --hn-btn-active-bg: rgb(176, 42.4, 55.2);
  --hn-btn-active-border-color: rgb(165, 39.75, 51.75);
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #fff;
  --hn-btn-disabled-bg: #dc3545;
  --hn-btn-disabled-border-color: #dc3545;
}
[data-bs-theme=dark] .btn-dark {
  --hn-btn-color: #fff;
  --hn-btn-bg: #212529;
  --hn-btn-border-color: #212529;
  --hn-btn-hover-color: #fff;
  --hn-btn-hover-bg: rgb(66.3, 69.7, 73.1);
  --hn-btn-hover-border-color: rgb(55.2, 58.8, 62.4);
  --hn-btn-focus-shadow-rgb: 66, 70, 73;
  --hn-btn-active-color: #fff;
  --hn-btn-active-bg: rgb(77.4, 80.6, 83.8);
  --hn-btn-active-border-color: rgb(55.2, 58.8, 62.4);
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #fff;
  --hn-btn-disabled-bg: #212529;
  --hn-btn-disabled-border-color: #212529;
}
[data-bs-theme=dark] .btn-light {
  --hn-btn-color: #000;
  --hn-btn-bg: #f8f9fa;
  --hn-btn-border-color: #f8f9fa;
  --hn-btn-hover-color: #000;
  --hn-btn-hover-bg: rgb(210.8, 211.65, 212.5);
  --hn-btn-hover-border-color: rgb(198.4, 199.2, 200);
  --hn-btn-focus-shadow-rgb: 211, 212, 213;
  --hn-btn-active-color: #000;
  --hn-btn-active-bg: rgb(198.4, 199.2, 200);
  --hn-btn-active-border-color: rgb(186, 186.75, 187.5);
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #000;
  --hn-btn-disabled-bg: #f8f9fa;
  --hn-btn-disabled-border-color: #f8f9fa;
}
[data-bs-theme=dark] .btn-outline-cta {
  --hn-btn-color: white;
  --hn-btn-border-color: white;
  --hn-btn-hover-color: #000;
  --hn-btn-hover-bg: white;
  --hn-btn-hover-border-color: white;
  --hn-btn-focus-shadow-rgb: 255, 255, 255;
  --hn-btn-active-color: #000;
  --hn-btn-active-bg: white;
  --hn-btn-active-border-color: white;
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: white;
  --hn-btn-disabled-bg: transparent;
  --hn-btn-disabled-border-color: white;
  --hn-gradient: none;
}
[data-bs-theme=dark] .btn-outline-primary {
  --hn-btn-color: #f39205;
  --hn-btn-border-color: #f39205;
  --hn-btn-hover-color: #000;
  --hn-btn-hover-bg: #f39205;
  --hn-btn-hover-border-color: #f39205;
  --hn-btn-focus-shadow-rgb: 243, 146, 5;
  --hn-btn-active-color: #000;
  --hn-btn-active-bg: #f39205;
  --hn-btn-active-border-color: #f39205;
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #f39205;
  --hn-btn-disabled-bg: transparent;
  --hn-btn-disabled-border-color: #f39205;
  --hn-gradient: none;
}
[data-bs-theme=dark] .btn-outline-secondary {
  --hn-btn-color: black;
  --hn-btn-border-color: black;
  --hn-btn-hover-color: #fff;
  --hn-btn-hover-bg: black;
  --hn-btn-hover-border-color: black;
  --hn-btn-focus-shadow-rgb: 0, 0, 0;
  --hn-btn-active-color: #fff;
  --hn-btn-active-bg: black;
  --hn-btn-active-border-color: black;
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: black;
  --hn-btn-disabled-bg: transparent;
  --hn-btn-disabled-border-color: black;
  --hn-gradient: none;
}
[data-bs-theme=dark] .btn-outline-default {
  --hn-btn-color: #d6d6d6;
  --hn-btn-border-color: #d6d6d6;
  --hn-btn-hover-color: #000;
  --hn-btn-hover-bg: #d6d6d6;
  --hn-btn-hover-border-color: #d6d6d6;
  --hn-btn-focus-shadow-rgb: 214, 214, 214;
  --hn-btn-active-color: #000;
  --hn-btn-active-bg: #d6d6d6;
  --hn-btn-active-border-color: #d6d6d6;
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #d6d6d6;
  --hn-btn-disabled-bg: transparent;
  --hn-btn-disabled-border-color: #d6d6d6;
  --hn-gradient: none;
}
[data-bs-theme=dark] .btn-outline-success {
  --hn-btn-color: #198754;
  --hn-btn-border-color: #198754;
  --hn-btn-hover-color: #fff;
  --hn-btn-hover-bg: #198754;
  --hn-btn-hover-border-color: #198754;
  --hn-btn-focus-shadow-rgb: 25, 135, 84;
  --hn-btn-active-color: #fff;
  --hn-btn-active-bg: #198754;
  --hn-btn-active-border-color: #198754;
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #198754;
  --hn-btn-disabled-bg: transparent;
  --hn-btn-disabled-border-color: #198754;
  --hn-gradient: none;
}
[data-bs-theme=dark] .btn-outline-info {
  --hn-btn-color: #354047;
  --hn-btn-border-color: #354047;
  --hn-btn-hover-color: #fff;
  --hn-btn-hover-bg: #354047;
  --hn-btn-hover-border-color: #354047;
  --hn-btn-focus-shadow-rgb: 53, 64, 71;
  --hn-btn-active-color: #fff;
  --hn-btn-active-bg: #354047;
  --hn-btn-active-border-color: #354047;
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #354047;
  --hn-btn-disabled-bg: transparent;
  --hn-btn-disabled-border-color: #354047;
  --hn-gradient: none;
}
[data-bs-theme=dark] .btn-outline-warning {
  --hn-btn-color: #ffc107;
  --hn-btn-border-color: #ffc107;
  --hn-btn-hover-color: #000;
  --hn-btn-hover-bg: #ffc107;
  --hn-btn-hover-border-color: #ffc107;
  --hn-btn-focus-shadow-rgb: 255, 193, 7;
  --hn-btn-active-color: #000;
  --hn-btn-active-bg: #ffc107;
  --hn-btn-active-border-color: #ffc107;
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #ffc107;
  --hn-btn-disabled-bg: transparent;
  --hn-btn-disabled-border-color: #ffc107;
  --hn-gradient: none;
}
[data-bs-theme=dark] .btn-outline-danger {
  --hn-btn-color: #dc3545;
  --hn-btn-border-color: #dc3545;
  --hn-btn-hover-color: #fff;
  --hn-btn-hover-bg: #dc3545;
  --hn-btn-hover-border-color: #dc3545;
  --hn-btn-focus-shadow-rgb: 220, 53, 69;
  --hn-btn-active-color: #fff;
  --hn-btn-active-bg: #dc3545;
  --hn-btn-active-border-color: #dc3545;
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #dc3545;
  --hn-btn-disabled-bg: transparent;
  --hn-btn-disabled-border-color: #dc3545;
  --hn-gradient: none;
}
[data-bs-theme=dark] .btn-outline-dark {
  --hn-btn-color: #212529;
  --hn-btn-border-color: #212529;
  --hn-btn-hover-color: #fff;
  --hn-btn-hover-bg: #212529;
  --hn-btn-hover-border-color: #212529;
  --hn-btn-focus-shadow-rgb: 33, 37, 41;
  --hn-btn-active-color: #fff;
  --hn-btn-active-bg: #212529;
  --hn-btn-active-border-color: #212529;
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #212529;
  --hn-btn-disabled-bg: transparent;
  --hn-btn-disabled-border-color: #212529;
  --hn-gradient: none;
}
[data-bs-theme=dark] .btn-outline-light {
  --hn-btn-color: #f8f9fa;
  --hn-btn-border-color: #f8f9fa;
  --hn-btn-hover-color: #000;
  --hn-btn-hover-bg: #f8f9fa;
  --hn-btn-hover-border-color: #f8f9fa;
  --hn-btn-focus-shadow-rgb: 248, 249, 250;
  --hn-btn-active-color: #000;
  --hn-btn-active-bg: #f8f9fa;
  --hn-btn-active-border-color: #f8f9fa;
  --hn-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --hn-btn-disabled-color: #f8f9fa;
  --hn-btn-disabled-bg: transparent;
  --hn-btn-disabled-border-color: #f8f9fa;
  --hn-gradient: none;
}
[data-bs-theme=dark] .header {
  --hn-header-bg: #000;
  --hn-header-color: #fff;
  --hn-header-navbar-toggle-color: rgb(255, 255, 255);
  --hn-header-navbar-toggle-bg: rgb(0, 0, 0);
  --hn-header-navbar-toggle-border-color: rgb(0, 0, 0);
}
[data-bs-theme=dark] .header__mainmenu {
  --hn-offcanvas-color: #d5cccc;
  --hn-offcanvas-bg: var(--hn-body-bg);
  --hn-offcanvas-border-color: #495057;
}
[data-bs-theme=dark] .header__mainmenu .nav-link {
  --hn-nav-link-color: #d5cccc;
  --hn-nav-link-hover-color: #f39205;
  --hn-nav-link-disabled-color: #d6d6d6;
}
[data-bs-theme=dark] .header__toggle {
  --hn-header-navbar-toggle-color: rgb(255, 255, 255);
  --hn-header-navbar-toggle-bg: rgb(0, 0, 0);
  --hn-header-navbar-toggle-border-color: rgb(0, 0, 0);
}
[data-bs-theme=dark] .header__toggle:hover {
  --hn-header-navbar-toggle-color: #f39205;
  --hn-header-navbar-toggle-bg: rgb(0, 0, 0);
  --hn-header-navbar-toggle-border-color: rgb(0, 0, 0);
}
[data-bs-theme=dark] .home .header--top .header__topmenu .nav > .nav-item > .nav-link,
[data-bs-theme=dark] .company .header--top .header__topmenu .nav > .nav-item > .nav-link,
[data-bs-theme=dark] .textheader .header--top .header__topmenu .nav > .nav-item > .nav-link {
  --hn-nav-link-color: #fff;
  --hn-nav-link-hover-color: #f39205;
  --hn-nav-link-disabled-color: #d6d6d6;
  --hn-nav-link-active-color: #d6d6d6;
}
[data-bs-theme=dark] .home .header--top .header__toggle,
[data-bs-theme=dark] .company .header--top .header__toggle,
[data-bs-theme=dark] .textheader .header--top .header__toggle {
  --hn-header-navbar-toggle-color: rgb(255, 255, 255);
  --hn-header-navbar-toggle-bg: rgb(0, 0, 0);
  --hn-header-navbar-toggle-border-color: rgb(0, 0, 0);
}
[data-bs-theme=dark] .home .header--top .header__toggle:hover,
[data-bs-theme=dark] .company .header--top .header__toggle:hover,
[data-bs-theme=dark] .textheader .header--top .header__toggle:hover {
  --hn-header-navbar-toggle-color: #f39205;
  --hn-header-navbar-toggle-bg: rgb(0, 0, 0);
  --hn-header-navbar-toggle-border-color: rgb(0, 0, 0);
}
[data-bs-theme=dark] .mainSlider {
  --hn-main-slider-bg: transparent;
  --hn-main-slider-color: var(--hn-headings-color);
  --hn-main-slider-title-color: #354047;
  --hn-main-slider-before-gradient: linear-gradient(to bottom, transparent 0%, rgba(255, 255, 255, 0) 100%);
  --hn-main-slider-after-gradient: linear-gradient(to top, transparent 0%, rgba(255, 255, 255, 0) 100%);
}
[data-bs-theme=dark] .header__topmenu .nav > .nav-item > .nav-link {
  --hn-nav-link-color: #d5cccc;
  --hn-nav-link-hover-color: #f39205;
  --hn-nav-link-active-color: #f39205;
  --hn-nav-link-disabled-color: #d6d6d6;
}
[data-bs-theme=dark] .services {
  --hn-services-title: var(--hn-heading-color, inherit);
  --hn-services-gradient: linear-gradient(to bottom, transparent 0%, rgba(255, 255, 255, 0) 100%);
}
[data-bs-theme=dark] .object.project,
[data-bs-theme=dark] .object_list .object,
[data-bs-theme=dark] .listings .object {
  --hn-object-bg: #354047;
  --hn-object-color: #d5cccc;
  --hn-object-link-color: var(--hn-link-color);
  --hn-object-link-hover-color: var(--hn-link-hover-color);
  --hn-object-title-color: var(--hn-headings-color);
  --hn-object-salestitle-color: var(--hn-object-color);
  --hn-object-feautre-color: var(--hn-object-link-color);
  --hn-object-stats-color: var(--hn-object-link-color);
  --hn-object-border-color: var(--hn-border-color);
  --hn-object-top-bg: #354047;
  --hn-object-top-color: #d5cccc;
  --hn-object-special-bg: #354047;
  --hn-object-special-color: #d5cccc;
  --hn-object-price-color: var(--hn-body-color);
  --hn-object-sub-price-color: var(--hn-body-color);
  --hn-object-icon-color: var(--hn-primary);
}
[data-bs-theme=dark] .pagination {
  --hn-pagination-color: var(--hn-body-color);
  --hn-pagination-bg: var(--hn-pageheader-bg);
  --hn-pagination-border-color: var(--hn-border-color);
  --hn-pagination-hover-color: var(--hn-primary);
  --hn-pagination-hover-bg: var(--hn-pageheader-bg);
  --hn-pagination-hover-border-color: var(--hn-border-color);
  --hn-pagination-focus-color: var(--hn-primary);
  --hn-pagination-focus-bg: #e9ecef;
  --hn-pagination-focus-box-shadow: 0 0 0 0.25rem, rgba(13, 110, 253, 0.25);
  --hn-pagination-active-color: #fff;
  --hn-pagination-active-bg: var(--hn-primary);
  --hn-pagination-active-border-color: var(--hn-primary);
  --hn-pagination-disabled-color: #6c757d;
  --hn-pagination-disabled-bg: #fff;
  --hn-pagination-disabled-border-color: #dee2e6;
}
[data-bs-theme=dark] .company__form {
  --hn-company-form-bg: #fff;
}

:root {
  --hn-brand-bg: #f5f6f7;
  --hn-primary-hover: rgb(218.0141129032, 130.9879032258, 4.4858870968);
  --hn-primary-light: #f8f8f8;
  --hn-primary-light-bg: #efefef;
  --letter-spacing-heading: 1px;
  --hn-standard-transition: all 250ms cubic-bezier(0.53, 0, 0, 1);
  --hn-form-control-bg: #f8f8f8;
  --hn-form-control-border: none;
  --step--1: clamp(0.8rem, calc(0.84rem + -0.03vw), 0.83rem);
  --step-0: clamp(1rem, calc(1rem + 0vw), 1rem);
  --step-1: clamp(1.2rem, calc(1.19rem + 0.05vw), 1.25rem);
  --step-2: clamp(1.44rem, calc(1.42rem + 0.12vw), 1.56rem);
  --step-3: clamp(1.73rem, calc(1.68rem + 0.23vw), 1.95rem);
  --step-4: clamp(2.07rem, calc(2rem + 0.37vw), 2.44rem);
  --step-5: clamp(2.49rem, calc(2.38rem + 0.56vw), 3.05rem);
}

@font-face {
  font-family: "Inter-fallback";
  size-adjust: 107%;
  src: local("Arial");
}
main {
  background-color: var(--hn-body-bg);
}

.btn-default {
  --hn-btn-bg: var(--hn-white);
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl,
.container-xxxl {
  max-width: 1320px;
}

p {
  font-weight: 300;
}
p strong {
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-style: normal;
  font-stretch: normal;
  font-weight: 300;
}

h1,
h1.section__title {
  font-size: calc(1.425rem + 2.1vw);
  line-height: 1.125;
}
@media (min-width: 1200px) {
  h1,
  h1.section__title {
    font-size: 3rem;
  }
}

h2,
.section__title {
  font-size: calc(1.375rem + 1.5vw);
  line-height: 1.125;
}
@media (min-width: 1200px) {
  h2,
  .section__title {
    font-size: 2.5rem;
  }
}

h2.section__title.title {
  margin-bottom: 2.5rem;
}

.btn {
  font-size: 1.125rem;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  font-weight: 500;
  margin-top: 1.5rem;
}
.btn.btn-primary, .btn.btn-outline-primary {
  --hn-btn-bg: var(--hn-primary);
  --hn-btn-border-color: var(--hn-primary);
  --hn-btn-hover-border-color: rgb(193.0282258065, 115.9758064516, 3.9717741935);
  --hn-btn-hover-bg: rgb(193.0282258065, 115.9758064516, 3.9717741935);
  --hn-btn-hover-color: var(--hn-white);
  --hn-btn-color: var(--hn-white);
}
.btn.btn-secondary {
  --hn-btn-bg: var(--hn-secondary);
  --hn-btn-border-color: var(--hn-secondary);
  --hn-btn-hover-border-color: rgb(74.7983870968, 90.3225806452, 100.2016129032);
  --hn-btn-hover-bg: rgb(74.7983870968, 90.3225806452, 100.2016129032);
  --hn-btn-hover-color: var(--hn-white);
  --hn-btn-color: var(--hn-white);
}
.btn.btn-white-outline {
  border: 1px solid var(--hn-white);
  color: var(--hn-white);
}
.btn.btn-white-outline:hover {
  border-color: var(--hn-primary-light);
  color: var(--hn-primary-light);
}

.section .text .btn-outlne-primary,
.section .text .btn-outline-secondary {
  margin-top: 2.5rem;
  margin-right: 1.5rem;
}

.pagination {
  --hn-pagination-bg: var(--hn-white);
  --hn-pagination-color: var(--hn-body-color);
}

.specialsSwiper > .container {
  padding-left: unset;
}
.specialsSwiper .object {
  --hn-object-border-radius: 0.5rem;
  box-shadow: unset;
  background: #faf8f2;
  height: auto;
}
.specialsSwiper .object__image {
  aspect-ratio: 1/1;
}
.specialsSwiper .object__image:before {
  display: none;
}
.specialsSwiper .object__header {
  width: 100%;
}
.specialsSwiper .object__data {
  width: 100%;
  background: unset;
  bottom: 0;
  padding: 1.25rem;
  min-height: unset;
  margin-top: unset;
  background: unset;
  align-items: flex-start;
}
.specialsSwiper .object__data .title,
.specialsSwiper .object__data .sub,
.specialsSwiper .object__data .price,
.specialsSwiper .object__data .price.pricesquaremeter,
.specialsSwiper .object__data .gwe {
  color: var(--hn-body-color);
  font-size: 0.9rem;
}
.specialsSwiper .object__data .title {
  font-weight: 500;
  font-size: 1.25rem;
  margin: 0.5rem 0;
}
.specialsSwiper .object__data .gwe,
.specialsSwiper .object__data .price.pricesquaremeter {
  text-align: right;
}
.specialsSwiper .object__icon {
  display: none;
}
.specialsSwiper .object__status {
  border-radius: 0.5rem;
  text-transform: uppercase;
  --hn-object-status-default-bg: var(--hn-body-color);
  --hn-object-status-default-color: var(--hn-white);
  --hn-object-status-new-bg: var(--hn-body-color);
  --hn-object-status-new-color: var(--hn-white);
  --hn-object-status-rentedsold-bg: var(--hn-body-color);
  --hn-object-status-rentedsold-color: var(--hn-white);
  --hn-object-status-conditions-bg: var(--hn-body-color);
  --hn-object-status-conditions-color: var(--hn-white);
  --hn-object-status-pricechanged-bg: var(--hn-body-color);
  --hn-object-status-pricechanged-color: var(--hn-white);
  --hn-object-status-topper-bg: var(--hn-body-color);
  --hn-object-status-topper-color: var(--hn-white);
  --hn-object-status-openhouse-bg: var(--hn-body-color);
  --hn-object-status-openhouse-color: var(--hn-white);
}

.custom-grid {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
}
@media (min-width: 992px) {
  .custom-grid {
    grid-auto-rows: 1fr;
  }
}
@media (min-width: 992px) {
  .custom-grid.w-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
.custom-grid.w-2 {
  gap: 2rem;
}
@media (min-width: 992px) {
  .custom-grid.w-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 992px) {
  .custom-grid.w-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}
.custom-grid__item.cta-block {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  overflow: hidden;
}
.custom-grid__item.cta-block_image {
  margin: 0.75rem;
}
.custom-grid__item.cta-block_image img {
  border-radius: 6px;
  aspect-ratio: 4/2.5;
  object-fit: cover;
  width: 100%;
}
.custom-grid__item.cta-block_text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;
  flex-grow: 1;
}
.custom-grid__item.bg-white {
  background: white;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.08);
}

.section:not(.swiper) {
  padding: calc(2rem + 3vw) 0;
}

.steps {
  display: grid;
  position: relative;
  z-index: 9;
  margin: calc(2.5rem + 2vw);
  gap: calc(2.5rem + 6vw);
}
.steps-item {
  display: flex;
  flex-direction: row;
  gap: 6rem;
  align-items: flex-start;
}
@media (min-width: 992px) {
  .steps-item {
    align-items: center;
  }
}
.steps-item__icon {
  max-width: 16.6666666667%;
  flex: 0 0 16.6666666667%;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--hn-body-color);
  background-color: var(--hn-white);
  text-align: center;
  border-radius: 0.5rem;
  outline: 1rem solid var(--hn-primary-light);
  font-size: calc(1.525rem + 3.3vw);
}
@media (min-width: 1200px) {
  .steps-item__icon {
    font-size: 4rem;
  }
}
@media (min-width: 992px) {
  .steps-item__icon {
    max-width: 16.6666666667%;
    flex: 0 0 16.6666666667%;
    aspect-ratio: 1/1;
  }
}
.steps-progressline {
  position: absolute;
  width: 1px;
  top: 0;
  background: var(--hn-primary);
  height: 100%;
  z-index: -1;
  left: 8.3333333333%;
}
.steps-progressline > div {
  position: absolute;
  width: 1px;
  background: var(--hn-primary);
  transition: height 1s cubic-bezier(0.4, 0.6, 0.2, 1);
}

.footer__menu {
  flex-grow: 1;
}
.footer__menu .nav .nav-link svg,
.footer__menu .nav .nav-link i {
  display: none;
}
.footer .company__icon {
  display: none;
}

.header {
  height: unset;
  justify-content: space-between;
  padding: 0.75rem 1.25rem;
  height: auto;
  align-items: stretch;
  max-width: 100vw;
}
@media (min-width: 992px) {
  .header {
    padding: 0.75rem 2.5rem;
  }
}
@media (min-width: 992px) {
  .header__toggle {
    display: none;
  }
}
.header--top {
  position: absolute;
  background-color: var(--hn-white);
}
.header--not-top {
  background-color: var(--hn-white);
}
.header--not-top:after {
  display: none;
}
.header__mainmenu .account-widget .title,
.header__mainmenu .account-widget .subtitle {
  color: var(--hn-body-color);
}
.header__mainmenu .account-widget .subtitle {
  --hn-subtitle-font-family: var(--hn-body);
}
.header__mainmenu.offcanvas .nav-link {
  justify-content: center;
  --hn-nav-link-color: var(--hn-body-color);
}
.header__mainmenu.offcanvas .header__toggle {
  background: unset;
  border: unset;
}
.header__mainmenu.offcanvas .header__toggle:before {
  content: "Sluiten";
  color: var(--hn-body-color);
}
.header__mainmenu.offcanvas .header__toggle svg {
  display: none;
}
.header__cta {
  order: 9;
  display: flex;
  padding-left: unset;
}
.header__cta .btn-cta {
  background: unset;
  font-size: 1rem;
  color: var(--hn-body-color);
  margin: unset;
  border: unset;
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
}
@media (min-width: 992px) {
  .header__cta .btn-cta.dropdown-toggle .icon {
    display: none;
  }
}
.header__cta .text {
  padding: unset;
}
.header__mainmenu .nav {
  align-items: center;
}
.header__mainmenu .btn {
  --hn-btn-border-radius: 1rem;
}
.header__topmenu #menu-2 > li:last-child {
  display: none;
}
.header > .nav-link {
  order: 9999;
  background: var(--hn-primary);
  border: none;
  margin-top: unset;
  align-self: center;
  font-size: 1.125rem;
  color: var(--hn-white);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-weight: 500;
}

.offcanvas .account-widget .btn {
  border: unset;
  padding: 0.75rem 1rem;
  font-size: 1.5rem;
  align-items: center;
}
.offcanvas .account-widget .btn span {
  font-weight: normal;
}
.offcanvas .nav {
  align-items: flex-start;
}
.offcanvas .nav .nav-item {
  width: 100%;
}
.offcanvas .nav .nav-item:last-of-type {
  order: 9999;
  background: var(--hn-primary);
  border: none;
  margin-top: unset;
  font-size: 1.125rem;
  color: var(--hn-white);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-weight: 500;
  width: auto;
}
.offcanvas .nav .nav-link {
  font-weight: 500;
  justify-content: space-between;
  color: var(--hn-body-color);
}
.offcanvas .header__logo {
  justify-content: flex-start;
}
.offcanvas-body > .nav > .nav-item:first-of-type {
  display: none;
}

.home .header--top .header__topmenu .nav > .nav-item .nav-link, .home .header--not-bottom .header__topmenu .nav > .nav-item .nav-link, .home .header--pinned .header__topmenu .nav > .nav-item .nav-link,
.company .header--top .header__topmenu .nav > .nav-item .nav-link,
.company .header--not-bottom .header__topmenu .nav > .nav-item .nav-link,
.company .header--pinned .header__topmenu .nav > .nav-item .nav-link,
.page .header--top .header__topmenu .nav > .nav-item .nav-link,
.page .header--not-bottom .header__topmenu .nav > .nav-item .nav-link,
.page .header--pinned .header__topmenu .nav > .nav-item .nav-link,
.member .header--top .header__topmenu .nav > .nav-item .nav-link,
.member .header--not-bottom .header__topmenu .nav > .nav-item .nav-link,
.member .header--pinned .header__topmenu .nav > .nav-item .nav-link,
.objects .header--top .header__topmenu .nav > .nav-item .nav-link,
.objects .header--not-bottom .header__topmenu .nav > .nav-item .nav-link,
.objects .header--pinned .header__topmenu .nav > .nav-item .nav-link,
.employees .header--top .header__topmenu .nav > .nav-item .nav-link,
.employees .header--not-bottom .header__topmenu .nav > .nav-item .nav-link,
.employees .header--pinned .header__topmenu .nav > .nav-item .nav-link {
  --hn-nav-link-font-size: 1.125rem;
  --hn-nav-link-color: var(--hn-body-color);
  --hn-nav-link-hover-color: var(--hn-primary);
}
.home .header--top .header__topmenu .nav > .nav-item .nav-link.active, .home .header--not-bottom .header__topmenu .nav > .nav-item .nav-link.active, .home .header--pinned .header__topmenu .nav > .nav-item .nav-link.active,
.company .header--top .header__topmenu .nav > .nav-item .nav-link.active,
.company .header--not-bottom .header__topmenu .nav > .nav-item .nav-link.active,
.company .header--pinned .header__topmenu .nav > .nav-item .nav-link.active,
.page .header--top .header__topmenu .nav > .nav-item .nav-link.active,
.page .header--not-bottom .header__topmenu .nav > .nav-item .nav-link.active,
.page .header--pinned .header__topmenu .nav > .nav-item .nav-link.active,
.member .header--top .header__topmenu .nav > .nav-item .nav-link.active,
.member .header--not-bottom .header__topmenu .nav > .nav-item .nav-link.active,
.member .header--pinned .header__topmenu .nav > .nav-item .nav-link.active,
.objects .header--top .header__topmenu .nav > .nav-item .nav-link.active,
.objects .header--not-bottom .header__topmenu .nav > .nav-item .nav-link.active,
.objects .header--pinned .header__topmenu .nav > .nav-item .nav-link.active,
.employees .header--top .header__topmenu .nav > .nav-item .nav-link.active,
.employees .header--not-bottom .header__topmenu .nav > .nav-item .nav-link.active,
.employees .header--pinned .header__topmenu .nav > .nav-item .nav-link.active {
  background: var(--hn-primary-light);
  border-radius: 0.5rem;
  color: var(--hn-primary);
}
.home .header--top .header__topmenu .nav > .nav-item .nav-link.active:hover, .home .header--not-bottom .header__topmenu .nav > .nav-item .nav-link.active:hover, .home .header--pinned .header__topmenu .nav > .nav-item .nav-link.active:hover,
.company .header--top .header__topmenu .nav > .nav-item .nav-link.active:hover,
.company .header--not-bottom .header__topmenu .nav > .nav-item .nav-link.active:hover,
.company .header--pinned .header__topmenu .nav > .nav-item .nav-link.active:hover,
.page .header--top .header__topmenu .nav > .nav-item .nav-link.active:hover,
.page .header--not-bottom .header__topmenu .nav > .nav-item .nav-link.active:hover,
.page .header--pinned .header__topmenu .nav > .nav-item .nav-link.active:hover,
.member .header--top .header__topmenu .nav > .nav-item .nav-link.active:hover,
.member .header--not-bottom .header__topmenu .nav > .nav-item .nav-link.active:hover,
.member .header--pinned .header__topmenu .nav > .nav-item .nav-link.active:hover,
.objects .header--top .header__topmenu .nav > .nav-item .nav-link.active:hover,
.objects .header--not-bottom .header__topmenu .nav > .nav-item .nav-link.active:hover,
.objects .header--pinned .header__topmenu .nav > .nav-item .nav-link.active:hover,
.employees .header--top .header__topmenu .nav > .nav-item .nav-link.active:hover,
.employees .header--not-bottom .header__topmenu .nav > .nav-item .nav-link.active:hover,
.employees .header--pinned .header__topmenu .nav > .nav-item .nav-link.active:hover {
  background: rgb(242.25, 242.25, 242.25);
}
.home .header--top .header__topmenu .nav .dropdown-menu, .home .header--not-bottom .header__topmenu .nav .dropdown-menu, .home .header--pinned .header__topmenu .nav .dropdown-menu,
.company .header--top .header__topmenu .nav .dropdown-menu,
.company .header--not-bottom .header__topmenu .nav .dropdown-menu,
.company .header--pinned .header__topmenu .nav .dropdown-menu,
.page .header--top .header__topmenu .nav .dropdown-menu,
.page .header--not-bottom .header__topmenu .nav .dropdown-menu,
.page .header--pinned .header__topmenu .nav .dropdown-menu,
.member .header--top .header__topmenu .nav .dropdown-menu,
.member .header--not-bottom .header__topmenu .nav .dropdown-menu,
.member .header--pinned .header__topmenu .nav .dropdown-menu,
.objects .header--top .header__topmenu .nav .dropdown-menu,
.objects .header--not-bottom .header__topmenu .nav .dropdown-menu,
.objects .header--pinned .header__topmenu .nav .dropdown-menu,
.employees .header--top .header__topmenu .nav .dropdown-menu,
.employees .header--not-bottom .header__topmenu .nav .dropdown-menu,
.employees .header--pinned .header__topmenu .nav .dropdown-menu {
  --hn-dropdown-min-width: 20rem;
}
.home .header--top .header__topmenu .nav .dropdown-menu > .nav-item .nav-link, .home .header--not-bottom .header__topmenu .nav .dropdown-menu > .nav-item .nav-link, .home .header--pinned .header__topmenu .nav .dropdown-menu > .nav-item .nav-link,
.company .header--top .header__topmenu .nav .dropdown-menu > .nav-item .nav-link,
.company .header--not-bottom .header__topmenu .nav .dropdown-menu > .nav-item .nav-link,
.company .header--pinned .header__topmenu .nav .dropdown-menu > .nav-item .nav-link,
.page .header--top .header__topmenu .nav .dropdown-menu > .nav-item .nav-link,
.page .header--not-bottom .header__topmenu .nav .dropdown-menu > .nav-item .nav-link,
.page .header--pinned .header__topmenu .nav .dropdown-menu > .nav-item .nav-link,
.member .header--top .header__topmenu .nav .dropdown-menu > .nav-item .nav-link,
.member .header--not-bottom .header__topmenu .nav .dropdown-menu > .nav-item .nav-link,
.member .header--pinned .header__topmenu .nav .dropdown-menu > .nav-item .nav-link,
.objects .header--top .header__topmenu .nav .dropdown-menu > .nav-item .nav-link,
.objects .header--not-bottom .header__topmenu .nav .dropdown-menu > .nav-item .nav-link,
.objects .header--pinned .header__topmenu .nav .dropdown-menu > .nav-item .nav-link,
.employees .header--top .header__topmenu .nav .dropdown-menu > .nav-item .nav-link,
.employees .header--not-bottom .header__topmenu .nav .dropdown-menu > .nav-item .nav-link,
.employees .header--pinned .header__topmenu .nav .dropdown-menu > .nav-item .nav-link {
  --hn-nav-link-color: var(--hn-body-color);
}
.home .header--top .header__toggle, .home .header--not-bottom .header__toggle, .home .header--pinned .header__toggle,
.company .header--top .header__toggle,
.company .header--not-bottom .header__toggle,
.company .header--pinned .header__toggle,
.page .header--top .header__toggle,
.page .header--not-bottom .header__toggle,
.page .header--pinned .header__toggle,
.member .header--top .header__toggle,
.member .header--not-bottom .header__toggle,
.member .header--pinned .header__toggle,
.objects .header--top .header__toggle,
.objects .header--not-bottom .header__toggle,
.objects .header--pinned .header__toggle,
.employees .header--top .header__toggle,
.employees .header--not-bottom .header__toggle,
.employees .header--pinned .header__toggle {
  border: unset;
  background: unset;
}
.home .header--top .header__logo, .home .header--not-bottom .header__logo, .home .header--pinned .header__logo,
.company .header--top .header__logo,
.company .header--not-bottom .header__logo,
.company .header--pinned .header__logo,
.page .header--top .header__logo,
.page .header--not-bottom .header__logo,
.page .header--pinned .header__logo,
.member .header--top .header__logo,
.member .header--not-bottom .header__logo,
.member .header--pinned .header__logo,
.objects .header--top .header__logo,
.objects .header--not-bottom .header__logo,
.objects .header--pinned .header__logo,
.employees .header--top .header__logo,
.employees .header--not-bottom .header__logo,
.employees .header--pinned .header__logo {
  justify-content: flex-start;
}

.page27 .offerlisting {
  display: none;
}

body,
html {
  max-width: 100vw;
  position: relative;
}

main {
  padding-top: 65px;
}

.home main {
  display: flex;
  flex-direction: column;
}
.home .mainSlider {
  height: auto;
  min-height: 540px;
}
@media (min-width: 992px) {
  .home .mainSlider {
    --hn-main-slider-subtitle-color: var(--hn-white);
    --hn-title-font-weight: 500;
    --hn-title-color: var(--hn-white);
    height: 75vh;
  }
}
.home .mainSlider .slider__slide:after {
  display: none;
}
.home .mainSlider .slider__slide:before {
  background: rgba(0, 0, 0, 0.3);
  height: 100%;
}
.home .mainSlider .swiper-fixed-content {
  position: absolute;
}
.home .mainSlider .swiper-slide-video {
  aspect-ratio: 16/8;
}
@media (min-width: 992px) {
  .home .mainSlider .swiper-slide-video {
    aspect-ratio: unset;
  }
}
.home .mainSlider .swiper-content {
  padding: 50px 0;
}
.home .mainSlider .swiper-content .title {
  font-size: calc(1.425rem + 2.1vw);
}
@media (min-width: 1200px) {
  .home .mainSlider .swiper-content .title {
    font-size: 3rem;
  }
}
@media (min-width: 992px) {
  .home .mainSlider .swiper-content .title {
    --hn-title-font-weight: 500;
    --hn-title-color: var(--hn-white);
  }
}
.home .mainSlider .swiper-content .subtitle {
  text-transform: unset;
  font-size: calc(1.3rem + 0.6vw);
  letter-spacing: unset;
}
@media (min-width: 1200px) {
  .home .mainSlider .swiper-content .subtitle {
    font-size: 1.75rem;
  }
}
@media (min-width: 992px) {
  .home .mainSlider .swiper-content .subtitle {
    --hn-main-slider-subtitle-color: var(--hn-white);
  }
}
.home .section.services {
  display: none;
}
.home .section.main {
  order: 0;
  padding: 0.75rem 0;
}
.home .section.main h1 {
  display: none;
}
.home .section.main > .container {
  max-width: unset;
  padding: 0 0.75rem;
}
.home .section.textblock1 {
  order: 1;
  text-align: center;
}
.home .section.textblock1 .text {
  justify-content: center;
}
.home .section.textblock2 {
  order: 2;
  background-color: var(--hn-primary-light);
}
.home .section.textblock2 .row {
  margin-bottom: 4rem;
  row-gap: 1.5rem;
  align-items: center;
  justify-content: center;
}
.home .section.textblock2 .row img {
  border-radius: var(--hn-border-radius);
  aspect-ratio: 2/1.2;
  object-fit: cover;
  width: 100%;
}
.home .section.specials {
  position: relative;
  order: 3;
}
.home .section.textblock3 {
  order: 4;
  background: var(--hn-primary-light);
}
.home .section.textblock4 {
  order: 5;
}
.home .section.textblock5 {
  order: 6;
  background: var(--hn-body-color);
}
.home .section.textblock5 h2,
.home .section.textblock5 p {
  color: var(--hn-white);
}
.home .section.textblock5 .text {
  text-align: center;
}
@media (min-width: 992px) {
  .home .section.textblock5 .text {
    max-width: 58.3333333333%;
    flex: 0 0 58.3333333333%;
    margin: 0 auto;
  }
}
.home .section.textblock6 {
  order: 7;
}
.home .section.textblock7 {
  order: 8;
}
.home .section.news {
  order: 9;
}
.home .section.textblock8 {
  order: 10;
  background-color: #beb3a7;
}
.home .section.textblock8 .text {
  flex-wrap: wrap;
  align-items: center;
}
@media (min-width: 992px) {
  .home .section.textblock8 .text {
    flex-wrap: nowrap;
  }
}
.home .section.textblock8 .text > * {
  width: auto;
}
.home .section.textblock8 .text h2 {
  flex-grow: 1;
  color: var(--hn-white);
}
.home .steps-item__icon {
  background: var(--hn-primary-light);
  outline-color: var(--hn-white);
}

.list-link {
  list-style: none;
  padding: unset;
}
.list-link > li {
  border-bottom: 1px solid rgba(53, 64, 71, 0.25);
  padding: 1rem 0;
}
.list-link__item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: var(--hn-primary);
  font-size: calc(1.325rem + 0.9vw);
  transition: 250ms ease;
  cursor: pointer;
}
@media (min-width: 1200px) {
  .list-link__item {
    font-size: 2rem;
  }
}
.list-link__item .icon {
  font-size: 75%;
}
.list-link__item:hover {
  padding-left: 2rem;
}

body:not(.page23, .home) .specials {
  display: none;
}

@media (min-width: 992px) {
  .section .text .btn {
    margin-bottom: unset;
  }
}

.button-grid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media (min-width: 1200px) {
  .button-grid {
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
  }
}
.button-grid__item {
  font-size: 1.25rem;
  margin: unset;
  padding: 1.25rem 2rem;
}
.button-grid__item:last-child:nth-child(3n-1) {
  grid-column: span 2;
  justify-self: center;
}

.usp-swiper {
  max-width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  padding: unset;
}
.usp-swiper .usp-grid__item {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  background-color: var(--hn-primary-light);
  padding: 2rem;
  border-radius: 0.5rem;
}
.usp-swiper .usp-grid__item-icon {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--hn-primary);
}
.usp-swiper .usp-grid__item-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.usp-swiper .usp-grid__item-text {
  font-size: calc(1.275rem + 0.3vw);
  font-weight: var(--hn-btn-font-weight);
  display: block;
}
@media (min-width: 1200px) {
  .usp-swiper .usp-grid__item-text {
    font-size: 1.5rem;
  }
}

.news {
  --hn-news-item-color: var(--hn-body-color);
  --hn-news-item-title-color: var(--hn-body-color);
}
.news .item__link {
  font-weight: 700;
}

.page .section.pageheader,
.objects .section.pageheader,
.member .section.pageheader,
.newsdetailpage .section.pageheader {
  --hn-pageheader-image-bg: var(--hn-primary-light);
  --hn-pageheader-bg: var(--hn-primary-light);
  --hn-pageheader-image-title-color: var(--hn-body-color);
  padding-top: 8rem;
}
.page .section.pageheader:not(.hasImage),
.objects .section.pageheader:not(.hasImage),
.member .section.pageheader:not(.hasImage),
.newsdetailpage .section.pageheader:not(.hasImage) {
  padding-bottom: unset;
}
.page .section.pageheader h1,
.page .section.pageheader p,
.objects .section.pageheader h1,
.objects .section.pageheader p,
.member .section.pageheader h1,
.member .section.pageheader p,
.newsdetailpage .section.pageheader h1,
.newsdetailpage .section.pageheader p {
  color: var(--hn-pageheader-image-title-color);
  text-align: center;
}
.page .section.pageheader .subtitle,
.objects .section.pageheader .subtitle,
.member .section.pageheader .subtitle,
.newsdetailpage .section.pageheader .subtitle {
  display: none;
}
.page .section.main:not(.hasImage),
.objects .section.main:not(.hasImage),
.member .section.main:not(.hasImage),
.newsdetailpage .section.main:not(.hasImage) {
  padding: calc(2rem + 1vw) 0;
  background-color: var(--hn-primary-light);
}
.page .section.textblock1,
.objects .section.textblock1,
.member .section.textblock1,
.newsdetailpage .section.textblock1 {
  background: var(--hn-white);
  overflow: visible;
  padding: 0.75rem 0;
}
.page .section.textblock1 > .container,
.objects .section.textblock1 > .container,
.member .section.textblock1 > .container,
.newsdetailpage .section.textblock1 > .container {
  max-width: unset;
  padding: 0 0.75rem;
}
.page .section.textblock3,
.objects .section.textblock3,
.member .section.textblock3,
.newsdetailpage .section.textblock3 {
  background-color: var(--hn-primary-light);
  position: relative;
}
.page .section.textblock5,
.objects .section.textblock5,
.member .section.textblock5,
.newsdetailpage .section.textblock5 {
  background-color: var(--hn-primary-light-bg);
}
.page .section.hasImage:not(.pageheader) .text p,
.objects .section.hasImage:not(.pageheader) .text p,
.member .section.hasImage:not(.pageheader) .text p,
.newsdetailpage .section.hasImage:not(.pageheader) .text p {
  width: 100%;
}
.page .section.hasImage:not(.pageheader) .image,
.objects .section.hasImage:not(.pageheader) .image,
.member .section.hasImage:not(.pageheader) .image,
.newsdetailpage .section.hasImage:not(.pageheader) .image {
  border-radius: unset;
}
.page .section.textblock7,
.objects .section.textblock7,
.member .section.textblock7,
.newsdetailpage .section.textblock7 {
  padding: unset;
}
.page .section.textblock7 .container,
.objects .section.textblock7 .container,
.member .section.textblock7 .container,
.newsdetailpage .section.textblock7 .container {
  max-width: unset;
  padding: unset;
}
.page .section.textblock7 .container .text,
.objects .section.textblock7 .container .text,
.member .section.textblock7 .container .text,
.newsdetailpage .section.textblock7 .container .text {
  display: none;
}
.page .section.textblock7 .container .image,
.objects .section.textblock7 .container .image,
.member .section.textblock7 .container .image,
.newsdetailpage .section.textblock7 .container .image {
  width: 100%;
  height: 70vh;
  object-fit: cover;
  border-radius: unset;
}

.page23 main {
  display: flex;
  flex-direction: column;
}
.page23 main .pageheader {
  order: 0;
}
.page23 main .main {
  order: 1;
}
.page23 main .section.textblock1.hasImage {
  order: 2;
}
.page23 main .section.textblock1.hasImage > .container .image {
  order: 1;
}
@media (min-width: 992px) {
  .page23 main .section.textblock1.hasImage > .container .text {
    order: 0;
    padding-left: unset;
  }
}
.page23 main .specials {
  order: 3;
}
.page23 main .textblock2 {
  order: 4;
}
.page23 main .textblock7 {
  order: 8;
}
.page23 main .textblock8 {
  order: 9;
}

.employees main {
  display: flex;
  flex-direction: column;
}
.employees main .section.pageheader {
  order: 0;
}
.employees main .section.main {
  order: 1;
}
.employees main .section.textblock1 {
  order: 2;
}
.employees main .section.textblock2 {
  order: 3;
}
.employees main .section.textblock3 {
  order: 4;
  padding-bottom: 2rem;
}
.employees main .section.employees {
  padding-top: unset;
  order: 5;
  background-color: var(--hn-primary-light);
}

.employees__roles {
  display: none;
}
.employees .employee__image {
  overflow: hidden;
}
.employees .employee__image img {
  height: 100%;
  object-fit: cover;
}
.employees .employee__preview {
  background-color: unset;
  border-radius: unset;
  display: flex;
  flex-direction: column;
}
.employees .employee__data {
  background: var(--hn-white);
  border-radius: var(--hn-border-radius);
  text-align: center;
  flex-grow: 1;
}

.company .section.pageheader {
  background: #f8f8f8;
}
.company .section.pageheader .text {
  padding-bottom: unset;
  max-width: unset !important;
}
.company .section.pageheader h1 {
  text-align: left;
  color: #000;
}
.company .section.pageheader.large {
  height: auto;
}
@media (min-width: 992px) {
  .company .section.pageheader.large {
    padding-top: 9rem;
  }
}
.company .section.pageheader.large > .container .text {
  padding-top: unset;
}
.company .section.contact .company__details-item,
.company .section.contact .company__finance {
  display: none;
}
.company > .container {
  flex-direction: column;
  align-items: center;
}
@media (min-width: 992px) {
  .company > .container {
    gap: 8.3333333%;
  }
}
.company__form {
  max-width: 100%;
  order: 1;
  padding: unset;
  background: none;
  box-shadow: unset;
}
.company__form .form-floating > label {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.company__form .comments .form-floating > label {
  align-items: flex-start;
}
.company__form .form-control {
  border-radius: unset;
  background: unset;
  border: unset;
}
.company__form .input-group-text {
  background-color: unset;
  border: unset;
  color: var(--hn-body-color);
  border-radius: unset;
}
.company__form .form-check-label {
  letter-spacing: 0.5px;
}
.company__form__form {
  width: 100%;
}
.company__form .form-floating > .form-control-plaintext ~ label,
.company__form .form-floating > .form-control:focus ~ label,
.company__form .form-floating > .form-control:not(:placeholder-shown) ~ label,
.company__form .form-floating > .form-select ~ label {
  transform: scale(0.65) translateY(-1.5rem) translateX(0.15rem);
}
.company__details {
  display: flex;
  flex-direction: column;
  padding-right: unset;
  flex: 1;
}
.company__details > div:last-of-type {
  order: 0;
}
.company__details > div:last-of-type h3 {
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.company__details > div:last-of-type > img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1/1.125;
}
.company__details-item {
  order: 1;
  display: block;
  font-size: 1.25rem;
}
.company__details-item svg,
.company__details-item i {
  display: none;
}
.company .textblock3 {
  margin-top: 4rem;
  overflow: visible !important;
}
.company .customCompany__info {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #fff;
  margin-top: -12rem;
}
.company .customCompany__info-iframe {
  width: 55%;
}
.company .customCompany__info-contactinfo {
  width: 45%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.company .customCompany__info-contactinfo img {
  max-width: 20rem;
  background-color: var(--hn-primary-light);
  padding: 1rem;
}
.company .customCompany__info-contactinfo p {
  margin-top: 2rem;
}

.objects .section.realestate {
  background: var(--hn-primary-light);
}
.objects .searchresults-header, .objects .searchresults-activefilters {
  background: unset;
  padding: unset;
}
.objects .searchresults-header .btn, .objects .searchresults-activefilters .btn {
  font-size: 1rem;
  margin-top: unset;
}
@media (min-width: 1200px) {
  .objects .objectgrid__list {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}
.objects .objectgrid__list .object__top.object {
  background: unset;
}
.objects .objectgrid__list .object__holder {
  background-color: unset;
}
.objects .objectgrid__list .object__data {
  background-color: var(--hn-white);
}
.objects .objectgrid__list .object_adtext {
  width: 100%;
}

.review_widget {
  width: 100%;
}

.objectsection.objectcontent {
  padding-bottom: 3rem;
}

.faqpage .accordion-body {
  color: #354047;
}

.page34 .shade,
.page107 .shade {
  width: 100vw;
  margin-left: -30px;
  margin-bottom: 2rem;
}
@media (min-width: 576px) {
  .page34 .shade,
  .page107 .shade {
    background-color: #000;
    position: absolute;
    display: block;
    z-index: 5;
    inset: -130px;
    width: unset;
    margin-left: unset;
    margin-bottom: unset;
  }
}
.page34 .section.pageheader,
.page107 .section.pageheader {
  display: none;
}
.page34 .section.pageheader .section__title,
.page107 .section.pageheader .section__title {
  display: none;
}
@media (min-width: 576px) {
  .page34 .section.main:not(.hasImage),
  .page107 .section.main:not(.hasImage) {
    padding: calc(4rem + 2vw) 0;
  }
}
@media (min-width: 768px) {
  .page34 .section.main:not(.hasImage),
  .page107 .section.main:not(.hasImage) {
    padding: calc(6rem + 2vw) 0;
  }
}
@media (min-width: 992px) {
  .page34 .section.main:not(.hasImage),
  .page107 .section.main:not(.hasImage) {
    padding: calc(10rem + 2vw) 0;
  }
}
@media (min-width: 1200px) {
  .page34 .section.main:not(.hasImage),
  .page107 .section.main:not(.hasImage) {
    padding: calc(10rem + 2vw) 0;
  }
}
@media (min-width: 1400px) {
  .page34 .section.main:not(.hasImage),
  .page107 .section.main:not(.hasImage) {
    padding: calc(14rem + 2vw) 0;
  }
}
.page34 .section.main,
.page107 .section.main {
  overflow: hidden;
  position: relative;
  padding: 0;
}
.page34 .section.main #background-video,
.page107 .section.main #background-video {
  width: 100%;
  inset: 0;
  height: auto;
  object-fit: cover;
  z-index: 0;
  top: -12px;
  left: -200px;
  align-self: center;
}
@media (min-width: 576px) {
  .page34 .section.main #background-video,
  .page107 .section.main #background-video {
    opacity: 0.5;
    left: -124px;
    width: 126%;
    position: absolute;
  }
}
@media (min-width: 768px) {
  .page34 .section.main #background-video,
  .page107 .section.main #background-video {
    width: 116%;
    left: -100px;
    top: 0px;
  }
}
@media (min-width: 992px) {
  .page34 .section.main #background-video,
  .page107 .section.main #background-video {
    width: 112%;
    top: 0px;
    left: -74px;
  }
}
@media (min-width: 1200px) {
  .page34 .section.main #background-video,
  .page107 .section.main #background-video {
    top: 0;
    left: 0;
    transform: translate(-90px, 10px);
  }
}
@media (min-width: 1400px) {
  .page34 .section.main #background-video,
  .page107 .section.main #background-video {
    width: 110%;
    left: 6px;
  }
}
.page34 .section.main .text-center,
.page107 .section.main .text-center {
  position: relative;
  z-index: 2;
  color: #000;
}
@media (min-width: 576px) {
  .page34 .section.main .text-center,
  .page107 .section.main .text-center {
    z-index: 6;
    color: #fff;
  }
}
.page34 .section.main .text,
.page107 .section.main .text {
  margin-bottom: 2rem;
  color: #000;
}
@media (min-width: 576px) {
  .page34 .section.main .text,
  .page107 .section.main .text {
    z-index: 6;
    color: #fff;
  }
}
.page34 .section.main .text p,
.page107 .section.main .text p {
  font-weight: 500;
}
.page34 .section.main .text h1,
.page107 .section.main .text h1 {
  text-align: center;
  margin-bottom: 6%;
}
@media (min-width: 576px) {
  .page34 .section.main .text h1,
  .page107 .section.main .text h1 {
    z-index: 6;
    color: #fff;
  }
}
.page34 .section.main .text h2,
.page107 .section.main .text h2 {
  display: none;
}
.page34 .textblock3 .section__title,
.page107 .textblock3 .section__title {
  text-align: center;
}
.page34 .textblock4 .container .row,
.page107 .textblock4 .container .row {
  margin-bottom: 4rem;
  row-gap: 1.5rem;
  align-items: center;
  justify-content: center;
}
.page34 .textblock4 .container .waardenbepalenbtn,
.page107 .textblock4 .container .waardenbepalenbtn {
  text-align: center;
  padding: 30px 0px 0px 0px;
}
.page34 .textblock4 .container .btn,
.page107 .textblock4 .container .btn {
  padding: 1rem 1rem;
}
.page34 .textblock4 .container p,
.page107 .textblock4 .container p {
  font-size: 20px;
}
.page34 .textblock4 .container h2.section__title,
.page107 .textblock4 .container h2.section__title {
  text-align-last: center;
}
.page34 .textblock8 .text,
.page107 .textblock8 .text {
  justify-content: center;
}
.page34 .header__topmenu #menu-2 li,
.page107 .header__topmenu #menu-2 li {
  display: none;
}
.page34 .header__topmenu #menu-2 li:last-child,
.page107 .header__topmenu #menu-2 li:last-child {
  display: flex;
}
.page34 .header__topmenu #menu-2 li:last-child .nav-link,
.page107 .header__topmenu #menu-2 li:last-child .nav-link {
  border-radius: 0.5rem;
  background-color: var(--hn-secondary);
}
.page34 .footer__sub,
.page107 .footer__sub {
  display: none;
}
.page34 .footer__social,
.page107 .footer__social {
  display: none;
}
.page34 .footer__menu,
.page107 .footer__menu {
  display: none;
}
.page34 button#ctaDropdownMenuButton,
.page107 button#ctaDropdownMenuButton {
  display: none;
}

.page59 .header {
  height: 66px;
}
.page59 .header .header__toggle-top {
  display: none;
}

.page103 .header__topmenu #menu-2 li,
.page104 .header__topmenu #menu-2 li,
.page59 .header__topmenu #menu-2 li {
  display: none;
}
.page103 .footer__sub,
.page104 .footer__sub,
.page59 .footer__sub {
  display: none;
}
.page103 .footer__social,
.page104 .footer__social,
.page59 .footer__social {
  display: none;
}
.page103 .footer__menu,
.page104 .footer__menu,
.page59 .footer__menu {
  display: none;
}
.page103 button#ctaDropdownMenuButton,
.page104 button#ctaDropdownMenuButton,
.page59 button#ctaDropdownMenuButton {
  display: none;
}

.page108 .section.pageheader .section__title {
  max-width: 960px;
  margin: 0 auto;
}
.page108 .section.textblock1 > .container {
  max-width: 960px;
}
.page108 .section.textblock3 .text {
  max-width: 960px;
  margin: 0 auto;
}
.page108 .section.textblock5 ._form_13 {
  margin: 0 auto;
}
.page108 .section.hasImage:not(.pageheader) .text {
  padding: 34px 0px 0px 32px;
}
@media (min-width: 768px) {
  .page108 .section.hasImage:not(.pageheader) .image {
    max-width: 100%;
  }
}

.page59 .textblock2 {
  display: none;
  visibility: hidden;
}

.page34 .header, .page59 .header, .page103 .header, .page104 .header {
  height: 66px;
}
.page34 .header .header__toggle-top, .page59 .header .header__toggle-top, .page103 .header .header__toggle-top, .page104 .header .header__toggle-top {
  display: none;
}

.section:has(.pixel-parcel) .container .text {
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.section:has(.pixel-parcel) .container > .image {
  display: none;
}
.section:has(.pixel-parcel) .pixel-parcel {
  display: none;
}

._form_element ._checkbox-radio input {
  appearance: auto;
}
._form_element select {
  padding: 4px;
  height: auto;
  border: 1px solid #979797;
  border-radius: 6px;
  color: #000 !important;
}

#_form_36_ {
  font-size: 14px;
  line-height: 1.6;
  font-family: arial, helvetica, sans-serif;
  margin: 0;
}

#_form_36_ * {
  outline: 0;
}

._form_hide {
  display: none;
  visibility: hidden;
}

._form_show {
  display: block;
  visibility: visible;
}

#_form_36_._form-top {
  top: 0;
}

#_form_36_._form-bottom {
  bottom: 0;
}

#_form_36_._form-left {
  left: 0;
}

#_form_36_._form-right {
  right: 0;
}

#_form_36_ input[type=text],
#_form_36_ input[type=tel],
#_form_36_ input[type=date],
#_form_36_ textarea {
  padding: 6px;
  height: auto;
  border: #979797 1px solid;
  border-radius: 4px;
  color: #000000 !important;
  font-size: 14px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#_form_36_ textarea {
  resize: none;
}

#_form_36_ ._submit {
  -webkit-appearance: none;
  cursor: pointer;
  font-family: arial, sans-serif;
  font-size: 14px;
  text-align: center;
  background: #f39207 !important;
  border: 0 !important;
  color: #f8f9fa !important;
  padding: 12px !important;
}

#_form_36_ ._submit:disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

#_form_36_ ._submit.processing {
  position: relative;
}

#_form_36_ ._submit.processing::before {
  content: "";
  width: 1em;
  height: 1em;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  border: double 3px transparent;
  border-radius: 50%;
  background-image: linear-gradient(#f39207, #f39207), conic-gradient(#f39207, #f8f9fa);
  background-origin: border-box;
  background-clip: content-box, border-box;
  animation: 1200ms ease 0s infinite normal none running _spin;
}

#_form_36_ ._submit.processing::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f39207 !important;
  border: 0 !important;
  color: #f8f9fa !important;
  padding: 12px !important;
}

@keyframes _spin {
  0% {
    transform: translate(-50%, -50%) rotate(90deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(450deg);
  }
}
#_form_36_ ._close-icon {
  cursor: pointer;
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/esfkyjh1u_forms-close-dark.png");
  background-repeat: no-repeat;
  background-size: 14.2px 14.2px;
  position: absolute;
  display: block;
  top: 11px;
  right: 9px;
  overflow: hidden;
  width: 16.2px;
  height: 16.2px;
}

#_form_36_ ._close-icon:before {
  position: relative;
}

#_form_36_ ._form-body {
  margin-bottom: 30px;
}

#_form_36_ ._form-image-left {
  width: 150px;
  float: left;
}

#_form_36_ ._form-content-right {
  margin-left: 164px;
}

#_form_36_ ._form-branding {
  color: #fff;
  font-size: 10px;
  clear: both;
  text-align: left;
  margin-top: 30px;
  font-weight: 100;
}

#_form_36_ ._form-branding ._logo {
  display: block;
  width: 130px;
  height: 14px;
  margin-top: 6px;
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/hh9ujqgv5_aclogo_li.png");
  background-size: 130px auto;
  background-repeat: no-repeat;
}

#_form_36_ .form-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

#_form_36_ ._form-label,
#_form_36_ ._form_element ._form-label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

#_form_36_._dark ._form-branding {
  color: #333;
}

#_form_36_._dark ._form-branding ._logo {
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/jftq2c8s_aclogo_dk.png");
}

#_form_36_ ._form_element {
  position: relative;
  margin-bottom: 10px;
  font-size: 0;
  max-width: 100%;
}

#_form_36_ ._form_element * {
  font-size: 14px;
}

#_form_36_ ._form_element._clear {
  clear: both;
  width: 100%;
  float: none;
}

#_form_36_ ._form_element._clear:after {
  clear: left;
}

#_form_36_ ._form_element input[type=text],
#_form_36_ ._form_element input[type=date],
#_form_36_ ._form_element select,
#_form_36_ ._form_element textarea:not(.g-recaptcha-response) {
  display: block;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: inherit;
}

#_form_36_ ._field-wrapper {
  position: relative;
}

#_form_36_ ._inline-style {
  float: left;
}

#_form_36_ ._inline-style input[type=text] {
  width: 150px;
}

#_form_36_ ._inline-style:not(._clear) + ._inline-style:not(._clear) {
  margin-left: 20px;
}

#_form_36_ ._form_element img._form-image {
  max-width: 100%;
}

#_form_36_ ._form_element ._form-fieldset {
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
  min-width: 0;
}

#_form_36_ ._clear-element {
  clear: left;
}

#_form_36_ ._full_width {
  width: 100%;
}

#_form_36_ ._form_full_field {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

#_form_36_ input[type=text]._has_error,
#_form_36_ textarea._has_error {
  border: #f37c7b 1px solid;
}

#_form_36_ input[type=checkbox]._has_error {
  outline: #f37c7b 1px solid;
}

#_form_36_ ._error {
  display: block;
  position: absolute;
  font-size: 14px;
  z-index: 10000001;
}

#_form_36_ ._error._above {
  padding-bottom: 4px;
  bottom: 39px;
  right: 0;
}

#_form_36_ ._error._below {
  padding-top: 8px;
  top: 100%;
  right: 0;
}

#_form_36_ ._error._above ._error-arrow {
  bottom: -4px;
  right: 15px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #ffdddd;
}

#_form_36_ ._error._below ._error-arrow {
  top: 0;
  right: 15px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #ffdddd;
}

#_form_36_ ._error-inner {
  padding: 12px 12px 12px 36px;
  background-color: #ffdddd;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM9 3V9H7V3H9ZM9 13V11H7V13H9Z' fill='%23CA0000'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 12px center;
  font-size: 14px;
  font-family: arial, sans-serif;
  font-weight: 600;
  line-height: 16px;
  color: #000;
  text-align: center;
  text-decoration: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(31, 33, 41, 0.298295);
}

#_form_36_ ._error-inner._form_error {
  margin-bottom: 5px;
  text-align: left;
}

#_form_36_ ._button-wrapper ._error-inner._form_error {
  position: static;
}

#_form_36_ ._error-inner._no_arrow {
  margin-bottom: 10px;
}

#_form_36_ ._error-arrow {
  position: absolute;
  width: 0;
  height: 0;
}

#_form_36_ ._error-html {
  margin-bottom: 10px;
}

.pika-single {
  z-index: 10000001 !important;
}

#_form_36_ input[type=text].datetime_date {
  width: 69%;
  display: inline;
}

#_form_36_ select.datetime_time {
  width: 29%;
  display: inline;
  height: 32px;
}

#_form_36_ input[type=date].datetime_date {
  width: 69%;
  display: inline-flex;
}

#_form_36_ input[type=time].datetime_time {
  width: 29%;
  display: inline-flex;
}

@media (min-width: 320px) and (max-width: 667px) {
  ::-webkit-scrollbar {
    display: none;
  }
  #_form_36_ {
    margin: 0;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  #_form_36_ * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 1em;
  }
  #_form_36_ ._form-content {
    margin: 0;
    width: 100%;
  }
  #_form_36_ ._form-inner {
    display: block;
    min-width: 100%;
  }
  #_form_36_ ._form-title,
  #_form_36_ ._inline-style {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
  }
  #_form_36_ ._form-title {
    font-size: 1.2em;
  }
  #_form_36_ ._form_element {
    margin: 0 0 20px;
    padding: 0;
    width: 100%;
  }
  #_form_36_ ._form-element,
  #_form_36_ ._inline-style,
  #_form_36_ input[type=text],
  #_form_36_ label,
  #_form_36_ p,
  #_form_36_ textarea:not(.g-recaptcha-response) {
    float: none;
    display: block;
    width: 100%;
  }
  #_form_36_ ._row._checkbox-radio label {
    display: inline;
  }
  #_form_36_ ._row,
  #_form_36_ p,
  #_form_36_ label {
    margin-bottom: 0.7em;
    width: 100%;
  }
  #_form_36_ ._row input[type=checkbox],
  #_form_36_ ._row input[type=radio] {
    margin: 0 !important;
    vertical-align: middle !important;
  }
  #_form_36_ ._row input[type=checkbox] + span label {
    display: inline;
  }
  #_form_36_ ._row span label {
    margin: 0 !important;
    width: initial !important;
    vertical-align: middle !important;
  }
  #_form_36_ ._form-image {
    max-width: 100%;
    height: auto !important;
  }
  #_form_36_ input[type=text] {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    line-height: 1.3em;
    -webkit-appearance: none;
  }
  #_form_36_ input[type=radio],
  #_form_36_ input[type=checkbox] {
    display: inline-block;
    width: 1.3em;
    height: 1.3em;
    font-size: 1em;
    margin: 0 0.3em 0 0;
    vertical-align: baseline;
  }
  #_form_36_ button[type=submit] {
    padding: 20px;
    font-size: 1.5em;
  }
  #_form_36_ ._inline-style {
    margin: 20px 0 0 !important;
  }
}
#_form_36_ {
  position: relative;
  text-align: left;
  margin: 25px auto 0;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #ffffff !important;
  border: 0px solid #b0b0b0 !important;
  max-width: 500px;
  -moz-border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  border-radius: 5px !important;
  color: #000000;
}

#_form_36_._inline-form ._form-title {
  font-family: "IBM Plex Sans", Helvetica, sans-serif;
  font-size: 22px;
  line-height: normal;
  font-weight: 600;
  color: #000000 !important;
  margin-bottom: 0;
}

#_form_36_._inline-form ._form-branding {
  font-family: "IBM Plex Sans", Helvetica, sans-serif;
  font-size: 13px;
  font-weight: 100;
  font-style: normal;
  text-decoration: none;
}

#_form_36_:before,
#_form_36_:after {
  content: " ";
  display: table;
}

#_form_36_:after {
  clear: both;
}

#_form_36_._inline-style {
  width: auto;
  display: inline-block;
}

#_form_36_._inline-style input[type=text],
#_form_36_._inline-style input[type=date] {
  padding: 10px 12px;
}

#_form_36_._inline-style button._inline-style {
  position: relative;
  top: 27px;
}

#_form_36_._inline-style p {
  margin: 0;
}

#_form_36_._inline-style ._button-wrapper {
  position: relative;
  margin: 27px 12.5px 0 20px;
}

#_form_36_ ._form-thank-you {
  position: relative;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 18px;
}

@media (min-width: 320px) and (max-width: 667px) {
  #_form_36_._inline-form._inline-style ._inline-style._button-wrapper {
    margin-top: 20px !important;
    margin-left: 0 !important;
  }
}
#_form_36_ .iti.iti--allow-dropdown.iti--separate-dial-code {
  width: 100%;
}

#_form_36_ .iti input {
  width: 100%;
  height: 32px;
  border: #979797 1px solid;
  border-radius: 4px;
}

#_form_36_ .iti--separate-dial-code .iti__selected-flag {
  background-color: #ffffff;
  border-radius: 4px;
}

#_form_36_ .iti--separate-dial-code .iti__selected-flag:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

#_form_36_ .iti__country-list {
  border-radius: 4px;
  margin-top: 4px;
  min-width: 460px;
}

#_form_36_ .iti__country-list--dropup {
  margin-bottom: 4px;
}

#_form_36_ .phone-error-hidden {
  display: none;
}

#_form_36_ .phone-error {
  color: #e40e49;
}

#_form_36_ .phone-input-error {
  border: 1px solid #e40e49 !important;
}

#_form_36_._inline-form ._form-content ._form-list-subscriptions-field fieldset {
  margin: 0;
  margin-bottom: 1.1428571429em;
  border: none;
  padding: 0;
}

#_form_36_._inline-form ._form-content ._form-list-subscriptions-field fieldset:last-child {
  margin-bottom: 0;
}

#_form_36_._inline-form ._form-content ._form-list-subscriptions-field legend {
  margin-bottom: 1.1428571429em;
}

#_form_36_._inline-form ._form-content ._form-list-subscriptions-field label {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 0.8571428571em;
}

#_form_36_._inline-form ._form-content ._form-list-subscriptions-field label:last-child {
  margin-bottom: 0;
}

#_form_36_._inline-form ._form-content ._form-list-subscriptions-field input {
  margin: 0;
  margin-right: 8px;
}

#_form_36_._inline-form ._form-content ._form-list-subscriptions-field ._form-checkbox-option-label {
  line-height: 1;
  display: block;
  font-weight: 700;
}

#_form_36_._inline-form ._form-content ._form-list-subscriptions-field ._form-checkbox-option-description {
  margin: 0;
  margin-top: 0.3333333333em;
  font-size: 0.8571428571em;
}

.employees.page9 .section.pageheader {
  --hn-pageheader-image-bg: var(--hn-primary-light);
  --hn-pageheader-bg: var(--hn-primary-light);
  --hn-pageheader-image-title-color: var(--hn-body-color);
}
.employees.page9 .section.pageheader .subtitle {
  display: none;
}
.employees.page9 .section.pageheader .title {
  text-align: center;
}
.employees.page9 .section.main .container {
  flex-direction: column;
}
.employees.page9 .section.main .container > .image {
  margin-top: -12rem;
  aspect-ratio: 2/1;
  border-radius: 0;
  width: 100%;
  max-width: 960px;
}
.employees.page9 .section.main .container > .image .image {
  aspect-ratio: 2/1;
  border-radius: 0;
}
.employees.page9 .section.main .container .text {
  padding-right: 0;
  width: 100%;
  max-width: 960px;
  text-align: center;
}
.employees.page9 .section.main .container .text p:first-child {
  margin-top: 2rem;
}
.employees.page9 .section:has(.review-grid) {
  background-color: var(--hn-primary-light);
}
.employees.page9 .section:has(.review-grid) .container {
  justify-content: center;
}
.employees.page9 .section:has(.review-grid) .container .text {
  padding-right: 0;
  width: 100%;
}
.employees.page9 .section:has(.review-grid) .container .text .section__title {
  text-align: center;
}
.employees.page9 .section:has(.review-grid) .container .text > p {
  text-align: center;
}
.employees.page9 .section:has(.review-grid) .container > .image {
  display: none;
}
.employees.page9 .section:has(.review-grid) .review-column {
  flex: unset;
}
.employees.page9 .section:has(.review-grid) .review-column + .review-column {
  margin-top: 4rem;
}
@media (min-width: 768px) {
  .employees.page9 .section:has(.review-grid) .review-column + .review-column {
    margin-top: unset;
  }
}
.employees.page9 .section:has(.review-grid) .review-source {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #fff;
  margin-bottom: 2rem;
  min-height: 12rem;
}
.employees.page9 .section:has(.review-grid) .review-source > img {
  height: 100%;
  width: auto;
  max-height: 10rem;
}
.employees.page9 .section:has(.review-grid) .review-highlight {
  background-color: #fff;
  padding: 2rem;
}
.employees.page9 .section:has(.review-grid) .review-author {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.employees.page9 .section:has(.review-grid) .review-author_img {
  width: 3rem;
  height: 3rem;
  background-color: #efefef;
  border-radius: 100%;
  margin-right: 2rem;
}
.employees.page9 .section:has(.review-grid) p:has(.btn) {
  margin: 2rem auto 0;
}
.employees.page9 .section.employees {
  order: 3;
  background-color: #ffffff;
  padding: calc(2rem + 3vw) 0;
}
.employees.page9 .section.employees .section__title {
  text-align: center;
}
@media (min-width: 1200px) {
  .employees.page9 .section.employees .employees__grid {
    grid-template-columns: minmax(250px, 1fr) minmax(250px, 1fr) minmax(250px, 1fr);
  }
}
.employees.page9 .section.employees .employees__grid .employee__image {
  aspect-ratio: 8/5;
  padding: 0.5rem;
  background-color: var(--hn-primary-light);
}
.employees.page9 .section.employees .employees__grid .employee__data {
  background-color: var(--hn-primary-light);
  border-radius: 0;
  text-align: start !important;
}
.employees.page9 .section.employees .employees__grid .employee__data .employee__role {
  color: #354047;
}
.employees.page9 .section.employees .employees__grid .employee__data .employee__role::after {
  content: "Lees bio →";
  display: block;
  padding-top: 2rem;
}
.employees.page9 .section.employees .employees__grid .employee__data .employee.employee__preview:hover .employee__role::after {
  color: #f39205;
}
.employees.page9 .section:has(.certified) {
  background-color: #e6f6ff;
}
.employees.page9 .section:has(.certified) .section__title {
  text-align: center;
}
.employees.page9 .section:has(.certified) .certified {
  width: 100%;
}
.employees.page9 .section:has(.certified) .certified a img {
  height: 100%;
  max-height: 10rem;
}
.employees.page9 .section:has(.certified) .certified .col-certified {
  flex: unset;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.employees.page9 .section:has(.certified) .certified .col-certified + .col-certified {
  margin-top: 1rem;
}
@media (min-width: 768px) {
  .employees.page9 .section:has(.certified) .certified .col-certified + .col-certified {
    margin-top: unset;
  }
}
.employees.page9 .section:has(.certified) .certified .certified-branchelogo {
  background-color: #fff;
  flex-grow: 1;
  width: 100%;
  text-align: center;
  padding: 2rem;
}
.employees.page9 .section:has(.preiframe) .text {
  align-items: center;
}
@media (min-width: 992px) {
  .employees.page9 .section:has(.preiframe) .text .preiframe {
    padding-right: calc(1.625rem + 4.5vw);
    width: 50%;
  }
}
.employees.page9 .section:has(.preiframe) .text .hasiframe {
  margin-top: 2rem;
  width: 100%;
}
@media (min-width: 992px) {
  .employees.page9 .section:has(.preiframe) .text .hasiframe {
    margin-top: unset;
    width: 50%;
  }
}
.employees.page9 .section.textblock4 {
  order: 4;
  background-color: #384046;
  color: #fff;
}
.employees.page9 .section.textblock4 .title {
  color: #fff;
}
.employees.page9 .section.textblock4 .image {
  border-radius: 0;
}
.employees.page9 .section.textblock5 {
  order: 5;
}
.employees.page9 .section.textblock5 .title {
  text-align: center;
}
.employees.page9 .section.textblock5 .hasYoutube {
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
}